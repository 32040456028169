export const WORDS = [
  'tomas',
  'lenta',
  'hobis',
  'kaltė',
  'medus',
  'silkė',
  'višta',
  'vožti',
  'valdo',
  'morka',
  'drobė',
  'meras',
  'menkė',
  'valio',
  'dingo',
  'bėgis',
  'groti',
  'kodėl',
  'sėkla',
  'laižo',
  'vagis',
  'ploja',
  'lopas',
  'tešla',
  'karvė',
  'kitas',
  'vargu',
  'verda',
  'malti',
  'birus',
  'tenka',
  'asmuo',
  'šilas',
  'žuvis',
  'diena',
  'pieva',
  'drovi',
  'pirma',
  'rūbai',
  'ponis',
  'tinka',
  'rojus',
  'lakus',
  'bulvė',
  'dirba',
  'gauti',
  'našus',
  'laužo',
  'knyga',
  'kovas',
  'norma',
  'gyvis',
  'ugnis',
  'dušas',
  'bėdos',
  'tolyn',
  'šukos',
  'austi',
  'doras',
  'visur',
  'augti',
  'geria',
  'ledas',
  'birža',
  'gulbė',
  'pildo',
  'drėko',
  'grupė',
  'karti',
  'alpti',
  'kerpė',
  'gelti',
  'dužti',
  'svyla',
  'girna',
  'mūšis',
  'uodas',
  'mirga',
  'ploti',
  'dūmas',
  'žuvys',
  'pešti',
  'kotas',
  'trina',
  'anūkė',
  'vyras',
  'šaldo',
  'akėti',
  'molis',
  'lūžta',
  'namie',
  'duobė',
  'delta',
  'kairė',
  'gilus',
  'mesti',
  'žodis',
  'daužo',
  'šildo',
  'velti',
  'mėgti',
  'rėkti',
  'kulti',
  'rieda',
  'gūžta',
  'tujos',
  'litas',
  'tarsi',
  'sagos',
  'vėjas',
  'skola',
  'genda',
  'perti',
  'saulė',
  'daužė',
  'tiras',
  'sloga',
  'dūkti',
  'kalvė',
  'kemša',
  'ignas',
  'bailė',
  'knisa',
  'tegul',
  'įmonė',
  'tigrė',
  'dalis',
  'kubas',
  'perša',
  'aršus',
  'poryt',
  'lakti',
  'nardo',
  'indas',
  'degti',
  'krato',
  'scena',
  'fonas',
  'gieda',
  'aukos',
  'šalis',
  'gidas',
  'petys',
  'čiupo',
  'žiema',
  'ponia',
  'želti',
  'troba',
  'skina',
  'beria',
  'valgo',
  'maudo',
  'kuras',
  'žavus',
  'įmoka',
  'šokis',
  'kopti',
  'tūris',
  'plyta',
  'supti',
  'kloja',
  'žygis',
  'korta',
  'angis',
  'kasti',
  'pigus',
  'šūvis',
  'grobė',
  'adyti',
  'melas',
  'avelė',
  'vieta',
  'tūzas',
  'plūdė',
  'godus',
  'vykti',
  'dzūkė',
  'groja',
  'meška',
  'balos',
  'krizė',
  'nokti',
  'gėris',
  'tvora',
  'guzas',
  'šalti',
  'vergė',
  'linas',
  'gatvė',
  'miega',
  'karys',
  'mušti',
  'tunas',
  'vėtra',
  'varlė',
  'švedė',
  'siena',
  'mūras',
  'vogti',
  'poras',
  'ferma',
  'paišo',
  'meilė',
  'duoti',
  'lekia',
  'žvakė',
  'kilpa',
  'mitas',
  'rūkti',
  'tulpė',
  'dreba',
  'tipas',
  'įduba',
  'duktė',
  'rokas',
  'lempa',
  'helis',
  'kurti',
  'todėl',
  'blusa',
  'mįslė',
  'migla',
  'gojus',
  'korys',
  'taurė',
  'alkis',
  'kruša',
  'garbė',
  'ikona',
  'opera',
  'švyti',
  'kyšis',
  'uoslė',
  'lemti',
  'tėvas',
  'bukas',
  'lynas',
  'gerti',
  'bėgti',
  'maišo',
  'rūbas',
  'lygis',
  'spėti',
  'dygti',
  'lieka',
  'kapsi',
  'poema',
  'brido',
  'dieta',
  'viela',
  'dujos',
  'barti',
  'proza',
  'bėras',
  'gaudo',
  'gelda',
  'šerti',
  'degus',
  'povas',
  'antis',
  'gulti',
  'taško',
  'urmas',
  'baimė',
  'jonas',
  'žemyn',
  'lubos',
  'geras',
  'kuopa',
  'vokas',
  'tušas',
  'lūšna',
  'lokys',
  'samdo',
  'bunda',
  'unija',
  'kerta',
  'bruko',
  'hiena',
  'laiku',
  'rytoj',
  'rėtis',
  'saugo',
  'natos',
  'vykdo',
  'dvynė',
  'kūnas',
  'pykti',
  'menas',
  'tyčia',
  'dirva',
  'marti',
  'voras',
  'pluša',
  'čekis',
  'sukti',
  'kloti',
  'šikna',
  'pikas',
  'lėtas',
  'gylis',
  'žymus',
  'poetė',
  'utena',
  'žmona',
  'urvas',
  'gesti',
  'tiesa',
  'globa',
  'taupo',
  'tarmė',
  'pinga',
  'vynas',
  'įgula',
  'dukra',
  'nerti',
  'pienė',
  'euras',
  'cypia',
  'lenda',
  'būdas',
  'kimba',
  'trise',
  'genys',
  'rūkas',
  'lepus',
  'cypsi',
  'pauzė',
  'žydra',
  'lipdo',
  'skyla',
  'penki',
  'deivė',
  'lesti',
  'kuoja',
  'aludė',
  'šokti',
  'pušis',
  'frazė',
  'kelmė',
  'gervė',
  'duria',
  'lupti',
  'bruka',
  'išorė',
  'šeima',
  'būrys',
  'pluta',
  'durys',
  'medis',
  'tiesė',
  'prašo',
  'skylė',
  'rūmai',
  'dešra',
  'kepti',
  'vysta',
  'neris',
  'drąsa',
  'lysvė',
  'mezga',
  'vilna',
  'derva',
  'rėmas',
  'akmuo',
  'dulkė',
  'pelkė',
  'karpo',
  'midus',
  'laumė',
  'durti',
  'tylus',
  'želia',
  'berti',
  'žūklė',
  'dulka',
  'dumti',
  'liepa',
  'kūgis',
  'firma',
  'colis',
  'šakės',
  'girdo',
  'kartu',
  'proga',
  'flora',
  'gotas',
  'šokas',
  'aušti',
  'dailė',
  'šėlti',
  'rūšis',
  'duona',
  'būvis',
  'rogės',
  'kobra',
  'gyvas',
  'mėnuo',
  'čerpė',
  'puota',
  'stepė',
  'auklė',
  'lovys',
  'bilda',
  'balti',
  'migdo',
  'ledai',
  'vonia',
  'rytas',
  'kūjis',
]
