import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'
import {Key} from "../keyboard/Key";

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Žaidimo taisyklės" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Atspėkite 5 raidžių lietuvišką žodį.
      </p>

        <p className="text-sm text-gray-500 dark:text-gray-300">
            Po įvesto spėjimo raidžių spalva parodys ar priartėjote prie tikslo:
        </p>


        <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="Ž"
          status="correct"
        />
        <Cell isRevealing={true} isCompleted={true}  status="absent"  value="I" />
        <Cell isRevealing={true}
              isCompleted={true} status="present"
              value="N" />
        <Cell isRevealing={true} isCompleted={true}  status="absent"  value="I" />
        <Cell isRevealing={true} isCompleted={true}  status="absent"  value="A" />
      </div>
        <p className="text-sm text-gray-500 dark:text-gray-300">
            ...
        </p>
        <div className="flex justify-center mb-1 mt-4">
            <Cell
                isRevealing={true}
                isCompleted={true}
                status="correct"
                value="Ž"
            />
            <Cell isRevealing={true} isCompleted={true}  status="correct"  value="E" />
            <Cell isRevealing={true}
                  isCompleted={true} status="correct"
                  value="M" />
            <Cell isRevealing={true} isCompleted={true}  status="correct"  value="Y" />
            <Cell isRevealing={true} isCompleted={true}  status="correct"  value="N" />
        </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Raidė Ž buvo parinktame žodyje teisingoje vietoje
      </p>

      <p className="text-sm text-gray-500 dark:text-gray-300">
        Raidė N buvo parinktame žodyje, bet kitoje vietoje
      </p>

        <div className="mt-3 flex justify-center mb-1">
          <Key
              width={140}
              value={'SUPRATAU'}
              onClick={handleClose}
          />
        </div>

      <p className="mt-6 italic text-sm text-gray-500 dark:text-gray-300">
            {' '}
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="underline font-bold"
        >
          Tai yra atvirojo kodo žaidimo Wordle versija
        </a>{' '}

          <p className="text-sm text-gray-500 dark:text-gray-300">
              Parengė T. Liubinas
          </p>

      </p>
    </BaseModal>
  )
}
