export const VALID_GUESSES = [
  'abejo',
  'abiem',
  'abrys',
  'abudu',
  'actas',
  'adata',
  'adelė',
  'adobe',
  'adyti',
  'afera',
  'afiša',
  'agata',
  'agatė',
  'agava',
  'agota',
  'aidas',
  'aidas',
  'aidis',
  'aidus',
  'aidys',
  'aiksi',
  'aiman',
  'ainis',
  'airis',
  'aistė',
  'aitra',
  'aižus',
  'akavo',
  'akelė',
  'aketė',
  'akija',
  'akijo',
  'akina',
  'akino',
  'aklas',
  'aklys',
  'akmuo',
  'aktas',
  'akutė',
  'akyti',
  'akėja',
  'akėjo',
  'akėti',
  'alavo',
  'albas',
  'aldas',
  'aldis',
  'alena',
  'aleta',
  'alfas',
  'algis',
  'aliai',
  'aliai',
  'alibi',
  'alina',
  'alina',
  'alino',
  'alinė',
  'alisa',
  'alius',
  'alkai',
  'alkas',
  'alkas',
  'alkis',
  'alkis',
  'alkti',
  'alkus',
  'almas',
  'almis',
  'alovė',
  'alpti',
  'alpus',
  'alpės',
  'alsas',
  'alsus',
  'altas',
  'aludė',
  'alvis',
  'alvys',
  'alyva',
  'alėja',
  'ameba',
  'amway',
  'anaip',
  'anavo',
  'andai',
  'andrė',
  'anelė',
  'angis',
  'anglė',
  'aniek',
  'anoji',
  'anojo',
  'anoks',
  'ansas',
  'antai',
  'antas',
  'antis',
  'antra',
  'anyta',
  'anąja',
  'anąją',
  'anąjį',
  'anūkė',
  'anųjų',
  'aorta',
  'apple',
  'apskr',
  'apsuk',
  'arabė',
  'ardas',
  'ardus',
  'arena',
  'arena',
  'areno',
  'arija',
  'arijė',
  'armuo',
  'arnas',
  'arpas',
  'artis',
  'artus',
  'artyn',
  'arvis',
  'arvys',
  'aršus',
  'arūnė',
  'asava',
  'ascii',
  'asilė',
  'asirė',
  'asist',
  'asmuo',
  'astas',
  'astma',
  'astra',
  'ataka',
  'atašė',
  'atgal',
  'atida',
  'atika',
  'atsak',
  'atstu',
  'atėnė',
  'audio',
  'audra',
  'audra',
  'audrė',
  'augas',
  'augis',
  'augti',
  'augus',
  'augys',
  'auklė',
  'auksė',
  'aulas',
  'aumuo',
  'auris',
  'aurys',
  'ausis',
  'austi',
  'austo',
  'austė',
  'austė',
  'autas',
  'aušra',
  'aušra',
  'aušrė',
  'aušta',
  'aušti',
  'avarė',
  'avelė',
  'avidė',
  'aviža',
  'avižė',
  'avėjo',
  'avėjo',
  'avėti',
  'avėti',
  'azija',
  'ašaka',
  'ašara',
  'ašarė',
  'aūlas',
  'babsi',
  'badas',
  'badus',
  'baido',
  'baidė',
  'baiga',
  'baigė',
  'bailė',
  'baimė',
  'baisa',
  'baisi',
  'bajus',
  'bakai',
  'bakas',
  'baksi',
  'bakst',
  'balas',
  'balda',
  'baldo',
  'baldė',
  'balnė',
  'balos',
  'balsa',
  'balsė',
  'balti',
  'baltė',
  'balys',
  'balža',
  'bamba',
  'bamba',
  'bambt',
  'banda',
  'banda',
  'bando',
  'bandė',
  'banga',
  'banga',
  'bantė',
  'barai',
  'baras',
  'barba',
  'barbė',
  'baris',
  'barti',
  'bartė',
  'bartė',
  'barža',
  'basas',
  'baskė',
  'basom',
  'basto',
  'bastė',
  'batas',
  'batys',
  'baubė',
  'bauda',
  'baudė',
  'bačka',
  'beata',
  'beigi',
  'beldė',
  'belgė',
  'belva',
  'bemat',
  'bemaž',
  'benas',
  'bepig',
  'beria',
  'berta',
  'berti',
  'beržė',
  'besti',
  'betgi',
  'bezjė',
  'bilda',
  'bildu',
  'bimbė',
  'birbt',
  'birbė',
  'birma',
  'birti',
  'birus',
  'birža',
  'biržė',
  'biržė',
  'bisas',
  'bitas',
  'bižai',
  'bjuro',
  'bjūra',
  'blaka',
  'blakt',
  'blakė',
  'blogo',
  'bluko',
  'blusa',
  'blusė',
  'blėso',
  'bobšė',
  'bodas',
  'bodus',
  'bomba',
  'bomžė',
  'bonas',
  'boras',
  'bosai',
  'bosas',
  'bosnė',
  'bosta',
  'bosta',
  'bosti',
  'bosti',
  'botas',
  'brada',
  'bravo',
  'brido',
  'brigė',
  'brika',
  'britė',
  'broma',
  'bronė',
  'brovė',
  'bruka',
  'bruko',
  'bruto',
  'brydė',
  'brėžė',
  'brūžė',
  'bubos',
  'bubsi',
  'budis',
  'budrė',
  'bugai',
  'bugdo',
  'bugdė',
  'buiza',
  'bukas',
  'bukas',
  'bukis',
  'bukta',
  'bukti',
  'bulvė',
  'bumas',
  'bumba',
  'bumbt',
  'bunda',
  'bunka',
  'buolė',
  'buožė',
  'burba',
  'burbė',
  'burga',
  'buria',
  'buria',
  'burko',
  'burkū',
  'burna',
  'burti',
  'busti',
  'butas',
  'butys',
  'buzai',
  'bužys',
  'bylus',
  'byris',
  'byzgė',
  'bėgis',
  'bėglė',
  'bėgom',
  'bėgte',
  'bėgti',
  'bėlys',
  'bėras',
  'bėris',
  'bėžis',
  'būbsi',
  'būdai',
  'būdas',
  'būdos',
  'būgai',
  'būgti',
  'būklė',
  'būras',
  'būrys',
  'būtis',
  'būtys',
  'būvis',
  'caksi',
  'canon',
  'capsi',
  'caras',
  'celas',
  'ceris',
  'cezis',
  'chamė',
  'ciksi',
  'cilas',
  'cinas',
  'cingt',
  'cirpt',
  'cirpė',
  'citit',
  'citra',
  'ciucė',
  'colis',
  'corel',
  'cvakt',
  'cypdo',
  'cypdė',
  'cypia',
  'cyplė',
  'cypsi',
  'cypti',
  'cypus',
  'cyrix',
  'dabar',
  'dagia',
  'dagnė',
  'dagus',
  'dagys',
  'dagys',
  'daiga',
  'daigo',
  'daigė',
  'dailė',
  'daina',
  'daina',
  'dainė',
  'dainė',
  'daira',
  'dairo',
  'dairo',
  'dairė',
  'dairė',
  'daiva',
  'dalba',
  'dalgė',
  'dalia',
  'dalia',
  'dalis',
  'dalka',
  'dalus',
  'damba',
  'danas',
  'danas',
  'danga',
  'dangė',
  'danis',
  'danys',
  'darda',
  'darga',
  'dargi',
  'dargo',
  'dargė',
  'darko',
  'darkė',
  'darna',
  'darus',
  'dasys',
  'dauba',
  'dauba',
  'daubė',
  'daubė',
  'dauga',
  'daugė',
  'daužo',
  'daužė',
  'dašis',
  'dažas',
  'deglė',
  'degte',
  'degti',
  'degus',
  'deivė',
  'delbė',
  'delfi',
  'delnė',
  'delsa',
  'delsė',
  'delta',
  'dengi',
  'dengė',
  'denis',
  'denys',
  'depas',
  'dergė',
  'dermė',
  'derva',
  'dešra',
  'dešrė',
  'dhtml',
  'diana',
  'didus',
  'diegė',
  'diena',
  'dieta',
  'digna',
  'dilba',
  'dilbt',
  'dilbė',
  'dildo',
  'dildė',
  'dildė',
  'dilga',
  'dilgt',
  'dilgė',
  'dilti',
  'dilus',
  'dimba',
  'dimša',
  'dindi',
  'dinga',
  'dingo',
  'dingt',
  'dirba',
  'dirbo',
  'dirgo',
  'diria',
  'dirig',
  'dirst',
  'dirsė',
  'dirti',
  'dirva',
  'diržo',
  'dičkė',
  'dobia',
  'dobti',
  'dogas',
  'dogma',
  'dokas',
  'domas',
  'domus',
  'doras',
  'doras',
  'dorys',
  'dovas',
  'dovis',
  'drama',
  'dreba',
  'drena',
  'drevė',
  'dribo',
  'dribt',
  'drika',
  'driko',
  'drikt',
  'drobė',
  'drova',
  'drovi',
  'drovi',
  'drožė',
  'druna',
  'drybt',
  'dryko',
  'drykt',
  'drąsa',
  'drėbt',
  'drėbė',
  'drėgo',
  'drėko',
  'drįso',
  'drūto',
  'drūžė',
  'dubas',
  'dubos',
  'dubti',
  'dubuo',
  'dubus',
  'dubės',
  'duiti',
  'dujos',
  'dukra',
  'duktė',
  'dulka',
  'dulko',
  'dulkt',
  'dulkė',
  'dulkė',
  'dulso',
  'dulti',
  'dumba',
  'dumia',
  'dumti',
  'dunda',
  'duoba',
  'duobė',
  'duobė',
  'duoda',
  'duokš',
  'duona',
  'duonė',
  'duoti',
  'duria',
  'durlė',
  'durnė',
  'durpė',
  'durst',
  'durti',
  'durys',
  'dusai',
  'dusas',
  'dusia',
  'dusna',
  'dusti',
  'dusyk',
  'dušas',
  'dužti',
  'dužus',
  'dvako',
  'dveja',
  'dveji',
  'dvejų',
  'dviem',
  'dviso',
  'dvokt',
  'dvokė',
  'dvylė',
  'dvynė',
  'dvėsė',
  'dydis',
  'dygis',
  'dyglė',
  'dygsi',
  'dygti',
  'dygus',
  'dygys',
  'dykas',
  'dykom',
  'dykra',
  'dykti',
  'dynys',
  'dysna',
  'dyžia',
  'dyžti',
  'dzūkė',
  'dėbsi',
  'dėbso',
  'dėklė',
  'dėkui',
  'dėlei',
  'dėlto',
  'dėmuo',
  'dėrti',
  'dėsto',
  'dėstė',
  'dėtas',
  'dėtis',
  'dūbso',
  'dūdai',
  'dūdos',
  'dūkis',
  'dūkti',
  'dūkus',
  'dūlas',
  'dūlis',
  'dūlus',
  'dūlys',
  'dūmas',
  'dūris',
  'dūsta',
  'dūzga',
  'dūzgė',
  'dūšia',
  'dūžis',
  'dūžta',
  'džinė',
  'edita',
  'edmas',
  'egzas',
  'eidas',
  'eidys',
  'eigas',
  'eigis',
  'eigys',
  'eimas',
  'eimys',
  'einas',
  'einis',
  'einus',
  'einys',
  'eisra',
  'eisys',
  'eitas',
  'eitys',
  'eivys',
  'eizbė',
  'eketė',
  'ekipa',
  'elada',
  'elena',
  'elfas',
  'elgia',
  'elgia',
  'elgti',
  'elgti',
  'eliza',
  'elmis',
  'elona',
  'elvas',
  'elvys',
  'emacs',
  'email',
  'emilė',
  'engia',
  'engti',
  'enter',
  'epson',
  'epušė',
  'erbis',
  'erdas',
  'erdmė',
  'erdvė',
  'erdys',
  'ergas',
  'erika',
  'erika',
  'ernis',
  'ersla',
  'ertmė',
  'erčia',
  'estas',
  'esybė',
  'etika',
  'euras',
  'excel',
  'ežena',
  'fanas',
  'fauna',
  'felis',
  'ferma',
  'ferma',
  'filol',
  'filos',
  'finas',
  'firma',
  'fixme',
  'flora',
  'flora',
  'folga',
  'fonas',
  'forma',
  'fotas',
  'frazė',
  'freza',
  'frida',
  'frotė',
  'frygė',
  'fryzė',
  'gabrė',
  'gabus',
  'gadvė',
  'gaida',
  'gaidė',
  'gaila',
  'gaila',
  'gaili',
  'gailė',
  'gairė',
  'gaiva',
  'gaiva',
  'gaivė',
  'gaišo',
  'gajus',
  'gajus',
  'gakta',
  'galai',
  'galas',
  'galgi',
  'galia',
  'galia',
  'galis',
  'galnė',
  'galop',
  'galva',
  'gamta',
  'gamža',
  'gandė',
  'ganta',
  'gantė',
  'ganus',
  'garas',
  'garbė',
  'gardė',
  'garga',
  'gargė',
  'garma',
  'garsė',
  'garus',
  'gatvė',
  'gaubi',
  'gaubė',
  'gaudo',
  'gaudė',
  'gaudė',
  'gaudė',
  'gauja',
  'gauna',
  'gaurė',
  'gaurė',
  'gausa',
  'gauti',
  'gaužė',
  'gašlė',
  'gconf',
  'gedas',
  'gegnė',
  'geiba',
  'geibo',
  'geidė',
  'geidė',
  'geiša',
  'gelda',
  'gelia',
  'gelmė',
  'gelta',
  'gelti',
  'gelto',
  'geltė',
  'gelvė',
  'gelžė',
  'gembė',
  'genas',
  'genda',
  'genys',
  'geras',
  'geras',
  'gerbė',
  'gerda',
  'geria',
  'germė',
  'gerta',
  'gerti',
  'gervė',
  'gesti',
  'getas',
  'gidas',
  'gieda',
  'giežė',
  'gikas',
  'gilda',
  'gildo',
  'gildė',
  'gilia',
  'gilis',
  'gilti',
  'gilus',
  'gilyn',
  'gilzė',
  'gilšė',
  'gimda',
  'gimdo',
  'gimdė',
  'gimti',
  'ginas',
  'ginda',
  'gindė',
  'ginta',
  'ginti',
  'gintė',
  'girda',
  'girdi',
  'girdo',
  'girdė',
  'girga',
  'girgt',
  'giria',
  'giria',
  'giria',
  'girma',
  'girna',
  'girti',
  'girto',
  'gižai',
  'gižta',
  'gižti',
  'gižus',
  'glebo',
  'glega',
  'glero',
  'gležo',
  'glito',
  'globa',
  'globė',
  'glosa',
  'gludo',
  'glumo',
  'glust',
  'glėbė',
  'glėza',
  'glęra',
  'glūdi',
  'glūdo',
  'glūma',
  'gnibt',
  'gnome',
  'gnome',
  'gnyba',
  'gnybo',
  'gnybt',
  'gnybė',
  'gobia',
  'gobis',
  'gobti',
  'gobus',
  'gobšė',
  'godai',
  'godas',
  'godus',
  'gogas',
  'gogla',
  'gojus',
  'gojus',
  'gokso',
  'golba',
  'gonys',
  'gotas',
  'gožia',
  'gožti',
  'grafa',
  'grasa',
  'graso',
  'grasė',
  'grasė',
  'grata',
  'greit',
  'greta',
  'greta',
  'grinė',
  'griso',
  'grobė',
  'grobė',
  'groja',
  'grojo',
  'groti',
  'groži',
  'grubo',
  'grudo',
  'gruma',
  'grupė',
  'grutė',
  'gružė',
  'gryta',
  'grytė',
  'grąža',
  'grąžo',
  'grąžė',
  'grėbė',
  'grėda',
  'grėsė',
  'grėta',
  'grėtė',
  'grėva',
  'gręžė',
  'grįžo',
  'grįžt',
  'grūda',
  'grūdo',
  'grūdė',
  'grūmė',
  'gubti',
  'gudai',
  'gudrė',
  'gugti',
  'guirė',
  'guiti',
  'gulbė',
  'guldo',
  'guldė',
  'gulom',
  'gulti',
  'gumba',
  'gumbo',
  'gumbė',
  'gumšo',
  'gunda',
  'gunda',
  'gundo',
  'gundė',
  'gundė',
  'gunga',
  'gunkt',
  'guoba',
  'guobė',
  'guoda',
  'guodė',
  'guogė',
  'guotė',
  'gurai',
  'gurbo',
  'gurgė',
  'gurkt',
  'gurna',
  'gurti',
  'gurus',
  'gusti',
  'gustė',
  'guvus',
  'guzas',
  'gužai',
  'gužas',
  'gvero',
  'gvėra',
  'gvęra',
  'gybti',
  'gylis',
  'gylys',
  'gymis',
  'gysla',
  'gyslė',
  'gysta',
  'gysti',
  'gytis',
  'gyvas',
  'gyvis',
  'gyžta',
  'gėjus',
  'gėlas',
  'gėris',
  'gęsta',
  'gūdas',
  'gūdis',
  'gūdus',
  'gūrai',
  'gūsis',
  'gūžia',
  'gūžta',
  'gūžti',
  'gūžys',
  'haris',
  'helga',
  'helis',
  'helix',
  'hidra',
  'hiena',
  'hilda',
  'hitas',
  'hobis',
  'howto',
  'https',
  'https',
  'hunas',
  'iberė',
  'icewm',
  'idant',
  'idilė',
  'iduna',
  'idėja',
  'ietis',
  'ieško',
  'ignas',
  'igoda',
  'ikona',
  'ikras',
  'ilgai',
  'ilgas',
  'ilgis',
  'ilgti',
  'ilgus',
  'ilgyn',
  'ilgšė',
  'ilona',
  'ilsta',
  'ilsti',
  'ilsus',
  'iltis',
  'ilyrė',
  'ilzbė',
  'imina',
  'iminė',
  'imlus',
  'imsto',
  'imstė',
  'imtis',
  'indas',
  'index',
  'indis',
  'indrė',
  'inesa',
  'ineza',
  'inkas',
  'intel',
  'inzgė',
  'irena',
  'irina',
  'irsta',
  'irsto',
  'irstė',
  'irzta',
  'irzti',
  'irzus',
  'istor',
  'italė',
  'itris',
  'iveta',
  'ivona',
  'išara',
  'išira',
  'išlup',
  'išorė',
  'išsyk',
  'išvis',
  'išėma',
  'iždas',
  'ižena',
  'ižina',
  'ižino',
  'ižyti',
  'jadvė',
  'jajai',
  'jajam',
  'jakai',
  'jakas',
  'jakas',
  'jakys',
  'jankė',
  'janys',
  'jarai',
  'jaras',
  'jasai',
  'jasas',
  'jaugi',
  'jaugė',
  'jauja',
  'jaukė',
  'jauna',
  'jaunė',
  'jaunė',
  'jaura',
  'jaura',
  'jauti',
  'jautė',
  'javas',
  'jeigu',
  'jidiš',
  'jiedu',
  'jieji',
  'jiems',
  'jinai',
  'jisai',
  'joana',
  'jocys',
  'jodas',
  'jogas',
  'jogys',
  'jokia',
  'jomis',
  'jonai',
  'jonas',
  'jonas',
  'jonis',
  'joris',
  'jotas',
  'jotas',
  'jotys',
  'judra',
  'judra',
  'judrė',
  'judrė',
  'judvi',
  'jukdo',
  'jukdė',
  'jukti',
  'jukus',
  'jukšė',
  'jumis',
  'junga',
  'jungė',
  'jungė',
  'junka',
  'junko',
  'junkė',
  'junta',
  'juoba',
  'juodo',
  'juodu',
  'juodė',
  'juodė',
  'juoju',
  'juokė',
  'juose',
  'juosi',
  'juosė',
  'juras',
  'jurga',
  'jurgė',
  'jurta',
  'juslė',
  'justa',
  'justi',
  'justė',
  'juška',
  'jėgos',
  'jėzus',
  'jūris',
  'kabus',
  'kabys',
  'kadai',
  'kadgi',
  'kailė',
  'kaina',
  'kaipo',
  'kaipo',
  'kairė',
  'kaita',
  'kaito',
  'kaitė',
  'kaiša',
  'kaišo',
  'kaišė',
  'kajus',
  'kakao',
  'kakta',
  'kakti',
  'kakši',
  'kakšt',
  'kalba',
  'kalba',
  'kalis',
  'kalkė',
  'kalnė',
  'kalti',
  'kaltė',
  'kalus',
  'kalva',
  'kalva',
  'kalvė',
  'kamgi',
  'kampa',
  'kamša',
  'kamša',
  'kamšo',
  'kamšė',
  'kamža',
  'kanai',
  'kanda',
  'kando',
  'kanka',
  'kantė',
  'kapas',
  'kapsi',
  'kapsė',
  'karas',
  'kardo',
  'kardė',
  'kargo',
  'kargė',
  'karia',
  'karka',
  'karkt',
  'karkė',
  'karna',
  'karpa',
  'karpo',
  'karpė',
  'karta',
  'karti',
  'karto',
  'karts',
  'kartu',
  'karvė',
  'karys',
  'karšo',
  'karšė',
  'kasta',
  'kasti',
  'kastė',
  'kasus',
  'katra',
  'katra',
  'katrė',
  'kaugė',
  'kaukė',
  'kaukė',
  'kaulė',
  'kauna',
  'kaupė',
  'kauti',
  'kaušo',
  'kaušė',
  'kazlų',
  'kazys',
  'kažin',
  'kegai',
  'keibo',
  'keikė',
  'keipa',
  'keipo',
  'keitė',
  'kekšė',
  'kelia',
  'kelis',
  'kelmė',
  'kelti',
  'keltė',
  'kempė',
  'kemsa',
  'kemša',
  'kemšė',
  'kengė',
  'kenkė',
  'kentė',
  'kepti',
  'kepši',
  'kepšt',
  'kerai',
  'keras',
  'kerbė',
  'kergė',
  'kerpa',
  'kerpė',
  'kerpė',
  'kerta',
  'kerti',
  'kertė',
  'keršė',
  'ketus',
  'kezai',
  'kečia',
  'kešas',
  'kežas',
  'kežti',
  'kežys',
  'kibai',
  'kibis',
  'kibti',
  'kibus',
  'kielė',
  'kieno',
  'kiksi',
  'kildo',
  'kildė',
  'kilkė',
  'kilmė',
  'kilna',
  'kilnė',
  'kilpa',
  'kilst',
  'kilti',
  'kilus',
  'kimba',
  'kimti',
  'kimus',
  'kimšo',
  'kinas',
  'kinda',
  'kinis',
  'kinka',
  'kinko',
  'kinkt',
  'kinkė',
  'kinta',
  'kintė',
  'kiožo',
  'kiras',
  'kirba',
  'kirba',
  'kirdo',
  'kirdė',
  'kirkt',
  'kirkė',
  'kirpo',
  'kirst',
  'kirto',
  'kiršo',
  'kisti',
  'kitas',
  'kitas',
  'kitur',
  'kiuro',
  'kiužo',
  'kiužė',
  'kiška',
  'kiškė',
  'kišti',
  'kiūra',
  'kiūri',
  'kiūst',
  'kiūti',
  'kiūto',
  'klaba',
  'klakt',
  'klapt',
  'klara',
  'klast',
  'klasė',
  'kleba',
  'klebo',
  'klega',
  'kleka',
  'kleko',
  'klekt',
  'klema',
  'klemė',
  'klept',
  'klepė',
  'klero',
  'klest',
  'kliba',
  'klibo',
  'klibt',
  'klibė',
  'klika',
  'kliro',
  'klišo',
  'klišė',
  'kloja',
  'kloja',
  'kloji',
  'klojo',
  'kloti',
  'klupo',
  'klupt',
  'kluso',
  'klydo',
  'klykė',
  'klypo',
  'klyra',
  'klėtė',
  'klęra',
  'klūpi',
  'knabo',
  'knapt',
  'knebo',
  'kneža',
  'knežo',
  'knežė',
  'knibo',
  'knisa',
  'kniso',
  'knisė',
  'knita',
  'knoja',
  'knojo',
  'knoti',
  'knyga',
  'knygė',
  'knykė',
  'knysa',
  'knyst',
  'knyvė',
  'kobra',
  'kodas',
  'kodul',
  'kodėl',
  'kokia',
  'kokis',
  'kolba',
  'kolei',
  'kopia',
  'kopia',
  'kopti',
  'kopti',
  'korta',
  'korys',
  'kostė',
  'kotas',
  'kotrė',
  'kovai',
  'kovas',
  'košia',
  'košti',
  'krago',
  'krakė',
  'krata',
  'krato',
  'kratė',
  'kreko',
  'krest',
  'kreta',
  'kreši',
  'krešo',
  'kriko',
  'kript',
  'kripė',
  'krito',
  'krivė',
  'krizė',
  'krona',
  'krova',
  'krovė',
  'kruna',
  'krunė',
  'krupo',
  'krust',
  'kruta',
  'kruta',
  'kruša',
  'kruša',
  'krušo',
  'krykė',
  'krypa',
  'krypo',
  'krypt',
  'krypu',
  'krėna',
  'krėtė',
  'krėtė',
  'krėvė',
  'krūpo',
  'krūpt',
  'krūva',
  'krūša',
  'kubas',
  'kudrė',
  'kuitė',
  'kukis',
  'kuksi',
  'kukti',
  'kukšt',
  'kulai',
  'kulda',
  'kulia',
  'kulka',
  'kulti',
  'kulva',
  'kulys',
  'kumsi',
  'kumši',
  'kumšt',
  'kunka',
  'kunta',
  'kuodė',
  'kuoja',
  'kuoka',
  'kuone',
  'kuopa',
  'kuopė',
  'kuosa',
  'kupra',
  'kuprė',
  'kupti',
  'kurai',
  'kuras',
  'kuras',
  'kurdė',
  'kurgi',
  'kuria',
  'kuria',
  'kurie',
  'kurio',
  'kuris',
  'kurią',
  'kurių',
  'kurkė',
  'kurkė',
  'kurmė',
  'kurna',
  'kurpo',
  'kurpė',
  'kurpė',
  'kurti',
  'kurto',
  'kurys',
  'kusai',
  'kusti',
  'kutai',
  'kutas',
  'kuzai',
  'kuzis',
  'kuzma',
  'kušta',
  'kužai',
  'kužda',
  'kvaka',
  'kvipo',
  'kvota',
  'kvotė',
  'kvoši',
  'kvošt',
  'kvykt',
  'kvykė',
  'kvėpt',
  'kvėpė',
  'kvėša',
  'kvėšo',
  'kylix',
  'kyšis',
  'kąsti',
  'kėbla',
  'kėnis',
  'kėpšt',
  'kėsti',
  'kęsai',
  'kęsti',
  'kęstė',
  'kęžta',
  'kūdas',
  'kūdra',
  'kūgis',
  'kūjai',
  'kūjis',
  'kūkai',
  'kūkas',
  'kūlis',
  'kūlis',
  'kūlys',
  'kūmas',
  'kūnas',
  'kūprė',
  'kūpso',
  'labai',
  'labas',
  'labas',
  'labys',
  'ladas',
  'ladys',
  'laida',
  'laido',
  'laidė',
  'laigo',
  'laigė',
  'laigė',
  'laiko',
  'laiku',
  'laikė',
  'laikė',
  'laima',
  'laimi',
  'laimė',
  'laimė',
  'laiva',
  'laivė',
  'laižo',
  'laižė',
  'lajus',
  'lajus',
  'lajus',
  'lakas',
  'lakta',
  'lakti',
  'lakus',
  'lalai',
  'lamdo',
  'lamdė',
  'lamžo',
  'lamžė',
  'landa',
  'lando',
  'landė',
  'lanka',
  'lanka',
  'lanko',
  'lankė',
  'lapas',
  'lapsi',
  'lapės',
  'latex',
  'lauke',
  'laukė',
  'laukė',
  'laumė',
  'laupo',
  'laupė',
  'laura',
  'laužo',
  'laužė',
  'lavus',
  'lazda',
  'lazga',
  'lašas',
  'lažai',
  'lažas',
  'ledai',
  'ledas',
  'legai',
  'leido',
  'leipo',
  'lekia',
  'lelai',
  'lemas',
  'lemia',
  'lempa',
  'lempa',
  'lemti',
  'lenas',
  'lenda',
  'lenkė',
  'lenkė',
  'lenta',
  'leona',
  'leonė',
  'lepsi',
  'lepti',
  'lepus',
  'lepšė',
  'lerva',
  'lesti',
  'lesus',
  'letas',
  'levas',
  'liana',
  'lieda',
  'lieja',
  'liejo',
  'lieka',
  'liepa',
  'liepa',
  'liepė',
  'lieti',
  'lietė',
  'liežė',
  'likdo',
  'likdė',
  'likti',
  'limfa',
  'limpa',
  'limti',
  'linas',
  'linas',
  'lindi',
  'lindo',
  'lingu',
  'lingė',
  'linki',
  'linko',
  'linkt',
  'linkė',
  'linux',
  'linzė',
  'lionė',
  'liovė',
  'lipdo',
  'lipdė',
  'lipdė',
  'lipti',
  'lipti',
  'lipus',
  'litas',
  'litis',
  'liucė',
  'liuda',
  'liudė',
  'liula',
  'liūdi',
  'liūdo',
  'liūlė',
  'liūtė',
  'ližai',
  'lobas',
  'lobis',
  'lobos',
  'lobti',
  'lobus',
  'lokys',
  'lokys',
  'lomus',
  'lopai',
  'lopas',
  'lovys',
  'lošia',
  'lošti',
  'lubos',
  'luiza',
  'luizė',
  'lukas',
  'lukna',
  'luoba',
  'luoba',
  'luokė',
  'luopt',
  'luošo',
  'luošė',
  'lupti',
  'lupus',
  'lydis',
  'lydus',
  'lydys',
  'lygis',
  'lygmė',
  'lygti',
  'lygus',
  'lynas',
  'lysta',
  'lysti',
  'lysvė',
  'lytis',
  'lyžis',
  'lyžta',
  'lyžti',
  'ląsta',
  'lėgai',
  'lėkis',
  'lėkti',
  'lėlys',
  'lėnas',
  'lėnas',
  'lėpti',
  'lėsta',
  'lėsti',
  'lėtas',
  'lėšos',
  'lęšis',
  'lįsti',
  'lūgnė',
  'lūšis',
  'lūšna',
  'lūšna',
  'lūžis',
  'lūžos',
  'lūžta',
  'lūžti',
  'lūžus',
  'macas',
  'macos',
  'magas',
  'magdė',
  'magma',
  'magus',
  'maigo',
  'maigė',
  'maino',
  'mainė',
  'maita',
  'maiva',
  'maivo',
  'maivė',
  'maiša',
  'maišo',
  'maišė',
  'maišė',
  'majas',
  'makas',
  'makna',
  'maksi',
  'malda',
  'maldo',
  'maldė',
  'malis',
  'malka',
  'malta',
  'malti',
  'malšo',
  'malžo',
  'malžė',
  'manas',
  'manga',
  'mango',
  'mangė',
  'manim',
  'manko',
  'mankė',
  'manta',
  'mantė',
  'manys',
  'manęs',
  'maras',
  'marcė',
  'margo',
  'margė',
  'marka',
  'markė',
  'marlė',
  'marma',
  'marta',
  'marti',
  'marus',
  'marša',
  'masto',
  'mastė',
  'matas',
  'matas',
  'matus',
  'matyt',
  'mauda',
  'maudo',
  'maudė',
  'mauko',
  'maukė',
  'mauna',
  'maurė',
  'mauti',
  'mazgo',
  'mazgė',
  'mačas',
  'mažas',
  'mažne',
  'mažti',
  'medas',
  'medis',
  'medus',
  'medys',
  'meilė',
  'meilė',
  'meišė',
  'mekai',
  'mekas',
  'meklė',
  'meknė',
  'meksi',
  'melas',
  'meldė',
  'melžė',
  'menas',
  'meniu',
  'menko',
  'menkė',
  'mentė',
  'mentė',
  'meras',
  'merdi',
  'merga',
  'mergė',
  'merkė',
  'mesti',
  'metas',
  'metro',
  'metus',
  'mezga',
  'mezgė',
  'mečys',
  'meška',
  'meškė',
  'midus',
  'miega',
  'miega',
  'miešė',
  'migdo',
  'migdė',
  'migis',
  'migla',
  'miglė',
  'miglė',
  'migti',
  'migšė',
  'mikai',
  'mikas',
  'mikna',
  'miknė',
  'miksa',
  'miksi',
  'milas',
  'milda',
  'miltė',
  'milžo',
  'minas',
  'mindo',
  'mindė',
  'minga',
  'minia',
  'minko',
  'minkė',
  'minta',
  'minti',
  'mintė',
  'minus',
  'mirga',
  'mirga',
  'mirko',
  'mirkt',
  'mirkė',
  'mirta',
  'mirti',
  'miršo',
  'misti',
  'mitas',
  'mitra',
  'mizgo',
  'mišti',
  'moiva',
  'mojis',
  'mokai',
  'moksl',
  'mokti',
  'mokus',
  'mokyt',
  'molas',
  'molis',
  'momuo',
  'morka',
  'morta',
  'morzė',
  'mudrė',
  'mudvi',
  'muižė',
  'mukna',
  'mukti',
  'mulas',
  'mulkė',
  'mumis',
  'munka',
  'murdo',
  'murdė',
  'murkt',
  'murkė',
  'murma',
  'murti',
  'murus',
  'murza',
  'murzė',
  'musas',
  'mušti',
  'mygti',
  'mykia',
  'mykti',
  'mylia',
  'myris',
  'myzga',
  'myšta',
  'myžti',
  'mąsto',
  'mąstė',
  'mąžta',
  'mėgti',
  'mėgys',
  'mėkla',
  'mėlis',
  'mėlti',
  'mėnuo',
  'mėtis',
  'mėšlė',
  'mėžia',
  'mėžti',
  'męsti',
  'mįslė',
  'mūkia',
  'mūkso',
  'mūkti',
  'mūrai',
  'mūras',
  'mūšia',
  'mūšis',
  'nacis',
  'nadas',
  'nafta',
  'nagas',
  'namas',
  'namie',
  'namol',
  'namon',
  'narai',
  'naras',
  'nardo',
  'nardė',
  'narna',
  'narna',
  'narsa',
  'narus',
  'narys',
  'naršo',
  'naršė',
  'nastė',
  'natas',
  'natos',
  'nauda',
  'naudė',
  'našlė',
  'našta',
  'našus',
  'negrė',
  'neigė',
  'neina',
  'neiti',
  'nejau',
  'nekas',
  'nekur',
  'nelma',
  'nencė',
  'neria',
  'neris',
  'nerka',
  'nerti',
  'neršė',
  'nesti',
  'netgi',
  'nešti',
  'nieko',
  'nikas',
  'nikis',
  'nikst',
  'nikti',
  'nilas',
  'nimfa',
  'ninka',
  'niovė',
  'nirti',
  'nirto',
  'niršo',
  'nitas',
  'niuko',
  'niukt',
  'niunė',
  'niuro',
  'nizgo',
  'niūko',
  'niūkt',
  'niūkė',
  'niūra',
  'niūra',
  'niūri',
  'niūro',
  'nižti',
  'nižus',
  'nokti',
  'nokus',
  'noras',
  'norma',
  'norom',
  'norta',
  'norti',
  'nortė',
  'norus',
  'nosis',
  'notra',
  'nulis',
  'nuogė',
  'nuoma',
  'nykti',
  'nykus',
  'nytis',
  'nytys',
  'nyzga',
  'nyžta',
  'nėmaž',
  'nėšis',
  'nūksi',
  'nūsta',
  'nūsti',
  'ochra',
  'odelė',
  'odena',
  'odesa',
  'odeta',
  'omega',
  'onutė',
  'opera',
  'opėja',
  'opėjo',
  'opėti',
  'orbit',
  'orija',
  'orėjo',
  'orėti',
  'osija',
  'osmis',
  'ošina',
  'ošino',
  'ošlys',
  'ožena',
  'ožinė',
  'padas',
  'pagal',
  'paiko',
  'paikė',
  'painė',
  'paiso',
  'paisė',
  'paiša',
  'paišo',
  'paišė',
  'pajus',
  'pakas',
  'pakol',
  'pakši',
  'pakšt',
  'palas',
  'palei',
  'palma',
  'palmė',
  'palvė',
  'palys',
  'palšo',
  'palšė',
  'pampa',
  'pampa',
  'pampo',
  'papas',
  'papkė',
  'papsi',
  'papua',
  'parpo',
  'parpė',
  'pasak',
  'pasas',
  'pasta',
  'patas',
  'patrė',
  'patys',
  'paulk',
  'paulė',
  'paupė',
  'pauzė',
  'pavad',
  'pačio',
  'pačiu',
  'pačių',
  'paėji',
  'pažas',
  'peikė',
  'pelas',
  'pelkė',
  'pelno',
  'pelnė',
  'pempė',
  'pemza',
  'penas',
  'penki',
  'peras',
  'peria',
  'perka',
  'persė',
  'perti',
  'perša',
  'perši',
  'petrė',
  'petys',
  'pevnė',
  'pešti',
  'pežai',
  'pienė',
  'piepo',
  'piept',
  'piepė',
  'pieta',
  'pieva',
  'pieša',
  'piešė',
  'pigti',
  'pigus',
  'pijus',
  'pikas',
  'pikis',
  'pikša',
  'pikši',
  'pikšt',
  'pildo',
  'pildė',
  'pilis',
  'pilis',
  'pilko',
  'pilkė',
  'pilti',
  'pilvė',
  'pilės',
  'pinas',
  'pinga',
  'pinti',
  'pipsi',
  'pirko',
  'pirma',
  'pirpė',
  'piršo',
  'pisti',
  'perdė',
  'pjesė',
  'pjova',
  'pjovė',
  'pjudo',
  'pjudė',
  'plaka',
  'plakė',
  'plast',
  'plepa',
  'plept',
  'plepė',
  'plero',
  'pliko',
  'plikė',
  'plikė',
  'plito',
  'plius',
  'ploja',
  'plojo',
  'ploti',
  'plova',
  'plovė',
  'pluko',
  'plukė',
  'pluta',
  'pluša',
  'pluša',
  'plušo',
  'plynė',
  'plyta',
  'plyti',
  'plyšo',
  'plėko',
  'plėnė',
  'plėtė',
  'plėvė',
  'plėša',
  'plėšo',
  'plėšė',
  'plūda',
  'plūdo',
  'plūdė',
  'plūdė',
  'plūkė',
  'plūst',
  'poema',
  'poetė',
  'pokši',
  'pokšt',
  'polai',
  'polis',
  'polis',
  'polka',
  'pompa',
  'ponas',
  'ponia',
  'ponis',
  'popas',
  'poras',
  'poryt',
  'povas',
  'poška',
  'poška',
  'praba',
  'praha',
  'pranė',
  'prato',
  'prašo',
  'prašė',
  'prekė',
  'prieš',
  'prima',
  'proga',
  'proxy',
  'proza',
  'prusa',
  'pruso',
  'pryga',
  'prūsė',
  'pudmė',
  'pudra',
  'puiko',
  'puikė',
  'pukši',
  'pukšt',
  'pulkė',
  'pulti',
  'pumpa',
  'pumpt',
  'punia',
  'punta',
  'puokė',
  'puola',
  'puolė',
  'puota',
  'puošė',
  'pupsi',
  'puris',
  'purka',
  'purpo',
  'purpt',
  'purpė',
  'purta',
  'purti',
  'purto',
  'purtė',
  'purus',
  'pusnė',
  'pusti',
  'pusto',
  'pustė',
  'pusėn',
  'putra',
  'puvus',
  'pučas',
  'pučia',
  'pušis',
  'puška',
  'puško',
  'pužai',
  'pužas',
  'pygis',
  'pykdo',
  'pykdė',
  'pykti',
  'pykši',
  'pykšt',
  'pypia',
  'pypkė',
  'pyplė',
  'pypsi',
  'pypti',
  'pyška',
  'pyšna',
  'pėdas',
  'pėpės',
  'pėžai',
  'pūdas',
  'pūkas',
  'pūkis',
  'pūkys',
  'pūkšt',
  'pūpsi',
  'pūpso',
  'pūras',
  'pūslė',
  'pūsti',
  'pūtis',
  'pūtvė',
  'pūška',
  'qmail',
  'radis',
  'radvė',
  'radys',
  'radža',
  'ragas',
  'ragas',
  'ragis',
  'ragys',
  'raibo',
  'raibė',
  'raida',
  'raidė',
  'raiko',
  'raikė',
  'raila',
  'rainė',
  'raito',
  'raitė',
  'raivo',
  'raivė',
  'raišo',
  'raišė',
  'raižo',
  'raižė',
  'rajus',
  'rajus',
  'rakai',
  'rakti',
  'rakys',
  'ralio',
  'ralis',
  'ramas',
  'ramdo',
  'ramdė',
  'rampa',
  'ramus',
  'ramys',
  'randa',
  'ranga',
  'rango',
  'rangė',
  'ranka',
  'ranka',
  'ranto',
  'rantė',
  'rasit',
  'rasti',
  'ratas',
  'rauda',
  'rauda',
  'raudo',
  'raudė',
  'raugė',
  'raugė',
  'rauko',
  'raukė',
  'rauna',
  'rauso',
  'rausė',
  'rauti',
  'ravai',
  'razma',
  'raško',
  'raškė',
  'ražai',
  'ražas',
  'redas',
  'regis',
  'regus',
  'reikė',
  'remia',
  'remti',
  'remžė',
  'rengė',
  'renis',
  'renka',
  'renta',
  'rentė',
  'repas',
  'retas',
  'retmė',
  'retur',
  'retys',
  'revai',
  'reviu',
  'rezga',
  'rezgė',
  'ridai',
  'rieda',
  'rieja',
  'riejo',
  'riekė',
  'riekė',
  'rieta',
  'rieti',
  'rietė',
  'rievė',
  'riešė',
  'rifas',
  'rikdo',
  'rikdė',
  'rikis',
  'rikti',
  'rikša',
  'rimas',
  'rimas',
  'rimti',
  'rimtė',
  'rimša',
  'rimšė',
  'ringė',
  'ringė',
  'rinka',
  'rinka',
  'rinko',
  'rinkė',
  'ripka',
  'risti',
  'riukt',
  'rizga',
  'rizgo',
  'rišti',
  'riūkt',
  'riūkė',
  'rodai',
  'rodis',
  'rodos',
  'rogės',
  'rojus',
  'rojus',
  'rokai',
  'rokas',
  'rokas',
  'romas',
  'romas',
  'romus',
  'roplė',
  'ropom',
  'rovus',
  'rudas',
  'rudis',
  'rudmė',
  'ruduo',
  'rufas',
  'rugys',
  'rukai',
  'rukla',
  'rukti',
  'rumba',
  'rumbė',
  'rumpa',
  'rumžė',
  'runda',
  'rungė',
  'runka',
  'ruopė',
  'ruoša',
  'ruošė',
  'ruplė',
  'rupti',
  'rupus',
  'rusas',
  'rusnė',
  'rusti',
  'rusvė',
  'rutka',
  'ruzga',
  'ručkė',
  'ruška',
  'rykas',
  'ryklė',
  'rytas',
  'rytis',
  'rytoj',
  'rytys',
  'rytys',
  'ryzga',
  'ryško',
  'ryšys',
  'ryžis',
  'ryžta',
  'ryžti',
  'rėkdo',
  'rėkdė',
  'rėkia',
  'rėklė',
  'rėkte',
  'rėkti',
  'rėkus',
  'rėmas',
  'rėmuo',
  'rėpia',
  'rėpka',
  'rėpla',
  'rėplė',
  'rėpso',
  'rėpti',
  'rėtis',
  'rėčka',
  'rėškė',
  'rėžia',
  'rėžis',
  'rėžti',
  'ręsti',
  'ręžia',
  'ręžti',
  'rūbas',
  'rūdis',
  'rūgti',
  'rūkas',
  'rūkna',
  'rūkti',
  'rūmas',
  'rūpus',
  'rūsys',
  'rūšis',
  'sabas',
  'sabys',
  'saida',
  'saira',
  'sakmė',
  'saksė',
  'sakus',
  'salai',
  'saldo',
  'saldo',
  'saldė',
  'saldė',
  'salos',
  'salpa',
  'salti',
  'salto',
  'salva',
  'salvė',
  'salys',
  'samai',
  'samba',
  'samda',
  'samdo',
  'samdė',
  'samus',
  'sapnė',
  'sargė',
  'sartė',
  'satya',
  'saudo',
  'sauga',
  'saugo',
  'saugo',
  'saugė',
  'sauja',
  'saulė',
  'saulė',
  'sauna',
  'sauso',
  'savas',
  'savas',
  'savim',
  'saviv',
  'savęs',
  'scena',
  'segti',
  'seiki',
  'seilė',
  'seklė',
  'sekta',
  'sekti',
  'selma',
  'sembė',
  'semia',
  'semti',
  'senas',
  'senis',
  'senka',
  'senti',
  'serbė',
  'serga',
  'sergi',
  'sesuo',
  'setas',
  'sfera',
  'sharp',
  'sieja',
  'siejo',
  'siekė',
  'siela',
  'siena',
  'siena',
  'siera',
  'sieti',
  'sigis',
  'silkė',
  'silpo',
  'silsi',
  'silva',
  'simas',
  'siras',
  'sirgo',
  'sirpo',
  'siuto',
  'siuva',
  'siuvo',
  'siūba',
  'siūbt',
  'siūlo',
  'siūlė',
  'siūlė',
  'siūti',
  'skabo',
  'skabė',
  'skala',
  'skalė',
  'skara',
  'skaro',
  'skato',
  'sketė',
  'skido',
  'skila',
  'skilo',
  'skina',
  'skira',
  'skitė',
  'skobė',
  'skola',
  'skoni',
  'skuba',
  'skuba',
  'skubo',
  'skuja',
  'skuro',
  'skuta',
  'skuto',
  'skydo',
  'skyla',
  'skylė',
  'skylė',
  'skynė',
  'skyrė',
  'skąra',
  'skėlė',
  'skėst',
  'skėtė',
  'skūra',
  'slapo',
  'slapė',
  'slavė',
  'slidė',
  'slist',
  'sloga',
  'slopa',
  'slopo',
  'slydo',
  'slypi',
  'slyst',
  'slyva',
  'slėga',
  'slėgė',
  'slėpė',
  'slūgo',
  'smakt',
  'smala',
  'smego',
  'smegt',
  'smigo',
  'smigt',
  'smilo',
  'smilė',
  'smogė',
  'smuko',
  'smukt',
  'smyla',
  'smėlo',
  'snigo',
  'snobė',
  'snuda',
  'snudė',
  'snučė',
  'snūdo',
  'sodai',
  'sodas',
  'sodra',
  'sokai',
  'sopti',
  'sopus',
  'sorbė',
  'sotis',
  'sotus',
  'spice',
  'spira',
  'spito',
  'spitė',
  'spora',
  'spuro',
  'spust',
  'spyna',
  'spyrė',
  'spėja',
  'spėjo',
  'spėka',
  'spėti',
  'spūda',
  'spūdi',
  'spūra',
  'spūst',
  'sravi',
  'srovi',
  'srovė',
  'srutė',
  'sruva',
  'sruvo',
  'srėbė',
  'srūti',
  'srūva',
  'stabt',
  'stakt',
  'stapt',
  'stasė',
  'stato',
  'statė',
  'stazė',
  'stebi',
  'stebo',
  'stefa',
  'stela',
  'stena',
  'stepa',
  'stepė',
  'stiga',
  'stigo',
  'stipo',
  'stiro',
  'stoja',
  'stojo',
  'stoka',
  'stoko',
  'stoti',
  'stovi',
  'stukt',
  'stula',
  'stybo',
  'styga',
  'stygo',
  'stypo',
  'stypt',
  'styra',
  'styra',
  'styri',
  'styro',
  'stėna',
  'stėro',
  'stūgt',
  'stūkt',
  'stūma',
  'stūmi',
  'stūmė',
  'sudie',
  'suite',
  'sukra',
  'sukte',
  'sukti',
  'sukčė',
  'sulig',
  'sulpa',
  'sulpė',
  'sunka',
  'sunko',
  'sunkė',
  'sunos',
  'suodė',
  'suoka',
  'suokė',
  'suomė',
  'suopa',
  'suopė',
  'suosa',
  'suosė',
  'supti',
  'surma',
  'susas',
  'suskė',
  'susna',
  'susti',
  'susyk',
  'sutrė',
  'suužo',
  'suvis',
  'svaja',
  'svaja',
  'svebė',
  'svida',
  'svido',
  'svilo',
  'svilė',
  'sviro',
  'svita',
  'svydi',
  'svyla',
  'svyla',
  'svylė',
  'svyra',
  'svyri',
  'svyro',
  'svėrė',
  'svėrė',
  'sykas',
  'sykis',
  'sykiu',
  'sėbai',
  'sėbrė',
  'sėdom',
  'sėkla',
  'sėkmė',
  'sėlos',
  'sėslė',
  'sėsti',
  'sūkis',
  'sūnus',
  'sūris',
  'sūrti',
  'sūrus',
  'sūsta',
  'tadas',
  'taiga',
  'taigi',
  'taika',
  'taiko',
  'taikė',
  'taisa',
  'taiso',
  'taisė',
  'tajai',
  'tajam',
  'tajas',
  'takas',
  'taksa',
  'taksi',
  'taksi',
  'takus',
  'takši',
  'takšo',
  'takšt',
  'talis',
  'talka',
  'talpa',
  'talžo',
  'talžė',
  'tampa',
  'tampo',
  'tampė',
  'tamsa',
  'tamso',
  'tanas',
  'tango',
  'tanka',
  'tanus',
  'tapsi',
  'tapti',
  'tapšt',
  'tardo',
  'tardė',
  'taria',
  'tarka',
  'tarmė',
  'tarpa',
  'tarpo',
  'tarsi',
  'tarti',
  'tarša',
  'taršo',
  'taršė',
  'tasai',
  'taukė',
  'taupa',
  'taupo',
  'taupė',
  'taura',
  'taurė',
  'taurė',
  'tausa',
  'tauta',
  'tauto',
  'tautė',
  'tauza',
  'tavas',
  'tavim',
  'tavęs',
  'tašas',
  'taško',
  'tašku',
  'taškė',
  'techn',
  'tegul',
  'teigė',
  'teikė',
  'teisė',
  'teisė',
  'tekis',
  'tekit',
  'teklė',
  'tekti',
  'tekus',
  'tekši',
  'tekšo',
  'tekšt',
  'tekšė',
  'telkė',
  'telpa',
  'telžė',
  'temdo',
  'temdė',
  'tempė',
  'temti',
  'tenai',
  'tenka',
  'teplė',
  'tepti',
  'tepus',
  'terba',
  'terla',
  'terlė',
  'terpė',
  'terpė',
  'teršė',
  'teška',
  'tešla',
  'težti',
  'tiara',
  'tiedu',
  'tieji',
  'tiekė',
  'tiesa',
  'tiesė',
  'tiesė',
  'tigrė',
  'tiksi',
  'tikti',
  'tikši',
  'tikšt',
  'tilda',
  'tildo',
  'tildė',
  'tildė',
  'tilin',
  'tilpo',
  'tilti',
  'tilžo',
  'tilžė',
  'timpa',
  'timpt',
  'tingi',
  'tingo',
  'tinka',
  'tinta',
  'tinti',
  'tipas',
  'tiras',
  'tiria',
  'tirpo',
  'tirpė',
  'tirta',
  'tirti',
  'tisas',
  'titas',
  'tiško',
  'tišku',
  'tižti',
  'tižus',
  'tobis',
  'todėl',
  'tokia',
  'tolei',
  'tolis',
  'tolti',
  'tolus',
  'tolyn',
  'tolys',
  'tomas',
  'tomas',
  'tonas',
  'topso',
  'toris',
  'tošis',
  'traka',
  'traka',
  'trakė',
  'trasa',
  'trata',
  'treja',
  'treji',
  'trejų',
  'trelė',
  'trept',
  'trepu',
  'treti',
  'trešo',
  'trijų',
  'triko',
  'triko',
  'trims',
  'trina',
  'tript',
  'tripu',
  'trise',
  'troba',
  'troff',
  'troja',
  'truko',
  'trukt',
  'trupa',
  'trupo',
  'trupė',
  'tryni',
  'trynė',
  'trynė',
  'trynė',
  'trypt',
  'trypu',
  'trypė',
  'trąša',
  'trėmė',
  'trėsė',
  'tręšė',
  'trūdė',
  'trūko',
  'trūkt',
  'trūni',
  'tufas',
  'tuklė',
  'tuksi',
  'tukti',
  'tulis',
  'tulpė',
  'tulys',
  'tulžo',
  'tunas',
  'tunka',
  'tuodu',
  'tuoju',
  'tuokė',
  'tuopa',
  'tupdo',
  'tupdė',
  'tupia',
  'turas',
  'turkė',
  'turkė',
  'turnė',
  'tursa',
  'turso',
  'turto',
  'turtė',
  'turšė',
  'tuska',
  'tusti',
  'tuzai',
  'tušas',
  'tvata',
  'tvata',
  'tvilo',
  'tvino',
  'tvoja',
  'tvojo',
  'tvora',
  'tvoti',
  'tvyla',
  'tvyla',
  'tvyra',
  'tvyro',
  'tvėrė',
  'tvėrė',
  'tyklė',
  'tykti',
  'tykus',
  'tykšo',
  'tykšt',
  'tylom',
  'tylus',
  'tymas',
  'tynis',
  'tyras',
  'tyras',
  'tyčia',
  'tyška',
  'tyžta',
  'tąsus',
  'tąsyk',
  'tėkmė',
  'tėkšt',
  'tėpis',
  'tėtis',
  'tetis',
  'tėvas',
  'tėškė',
  'tęsia',
  'tęsti',
  'tęžta',
  'tįsis',
  'tįsla',
  'tįslė',
  'tįsta',
  'tįsti',
  'tįsus',
  'tūbas',
  'tūkst',
  'tūlas',
  'tūlis',
  'tūnus',
  'tūpia',
  'tūpsi',
  'tūpti',
  'tūris',
  'tūsta',
  'tūzas',
  'tūžis',
  'tūžta',
  'tūžti',
  'ubagė',
  'udrys',
  'ugnis',
  'ugras',
  'unija',
  'unija',
  'unitė',
  'uodas',
  'uodis',
  'uolus',
  'uosis',
  'uosis',
  'uoslė',
  'uosti',
  'uosto',
  'uostė',
  'uotas',
  'uošas',
  'uošvė',
  'upiai',
  'upyna',
  'upytė',
  'urbia',
  'urbti',
  'urena',
  'ureno',
  'urmas',
  'urpia',
  'urpti',
  'ursas',
  'urvas',
  'urzda',
  'urzga',
  'urzgė',
  'urėdė',
  'urškė',
  'uršlė',
  'uršti',
  'urštė',
  'usnis',
  'utena',
  'utėlė',
  'užtai',
  'užtat',
  'užuot',
  'užupė',
  'užvis',
  'vacys',
  'vadai',
  'vadas',
  'vadus',
  'vagia',
  'vagis',
  'vaida',
  'vaiga',
  'vaigė',
  'vaiko',
  'vaikė',
  'vainė',
  'vaipo',
  'vaipė',
  'vaira',
  'vaisa',
  'vaiso',
  'vaisė',
  'vaiva',
  'vajus',
  'vajus',
  'vakar',
  'valai',
  'valas',
  'valda',
  'valda',
  'valdo',
  'valdo',
  'valdė',
  'valdė',
  'valdė',
  'valgo',
  'valgė',
  'valia',
  'valia',
  'valio',
  'valus',
  'valys',
  'vampt',
  'vanda',
  'vanga',
  'vanta',
  'vaplė',
  'vapsi',
  'vapus',
  'varas',
  'varda',
  'vargo',
  'vargu',
  'varis',
  'varlė',
  'varna',
  'varnė',
  'varpa',
  'varpo',
  'varpė',
  'varsa',
  'varta',
  'varto',
  'vartė',
  'varus',
  'varva',
  'varys',
  'varės',
  'varža',
  'varžo',
  'varžė',
  'vatas',
  'vaukt',
  'vaura',
  'vašus',
  'važis',
  'važma',
  'važta',
  'važus',
  'važys',
  'vcard',
  'vebla',
  'veblė',
  'vedlė',
  'veika',
  'veikė',
  'veisė',
  'vejos',
  'veldi',
  'velia',
  'velka',
  'velkė',
  'velta',
  'velti',
  'vemia',
  'vempa',
  'vemti',
  'vengė',
  'venta',
  'ventė',
  'vepai',
  'vepia',
  'vepti',
  'veras',
  'verba',
  'verda',
  'vergė',
  'vergė',
  'veria',
  'verkė',
  'verpė',
  'verta',
  'verti',
  'vertė',
  'vertė',
  'verži',
  'veržė',
  'vesta',
  'vesti',
  'večas',
  'vešus',
  'vežti',
  'vidas',
  'video',
  'viduj',
  'vidur',
  'vidus',
  'viela',
  'viens',
  'viepė',
  'vieta',
  'vieši',
  'viešė',
  'vikis',
  'vikis',
  'viksi',
  'vikst',
  'viktė',
  'vilas',
  'vilba',
  'vilgo',
  'vilgė',
  'vilia',
  'vilki',
  'vilko',
  'vilkė',
  'vilma',
  'vilna',
  'vilti',
  'viltė',
  'vilys',
  'vimdo',
  'vimdė',
  'vimpa',
  'vincė',
  'vingė',
  'vinis',
  'violė',
  'vipsi',
  'vipti',
  'virba',
  'virbo',
  'virbė',
  'virga',
  'viris',
  'virpa',
  'virti',
  'virto',
  'virvė',
  'virži',
  'visad',
  'visai',
  'visas',
  'visgi',
  'visgė',
  'viska',
  'visko',
  'viską',
  'vispa',
  'visti',
  'visur',
  'vitas',
  'vizga',
  'vizgė',
  'višta',
  'vlada',
  'vladė',
  'vogis',
  'vogra',
  'vogti',
  'vokas',
  'vokia',
  'voksa',
  'vokti',
  'volai',
  'volas',
  'vonia',
  'voras',
  'votis',
  'vovos',
  'vožia',
  'vožti',
  'vydas',
  'vygas',
  'vykdo',
  'vykdė',
  'vykti',
  'vylus',
  'vynas',
  'vypla',
  'vypso',
  'vypti',
  'vyras',
  'vyris',
  'vyrus',
  'vysta',
  'vysti',
  'vysto',
  'vystė',
  'vytas',
  'vytis',
  'vytis',
  'vyžia',
  'vyžti',
  'vąšas',
  'vėbra',
  'vėdlė',
  'vėjas',
  'vėlai',
  'vėlei',
  'vėlgi',
  'vėlus',
  'vėmis',
  'vėpla',
  'vėplė',
  'vėpso',
  'vėpti',
  'vėsis',
  'vėsmė',
  'vėsta',
  'vėsti',
  'vėsus',
  'vėtra',
  'vėžys',
  'xterm',
  'yahoo',
  'ygzti',
  'yliai',
  'ylius',
  'yvavo',
  'yzgia',
  'zajai',
  'zambė',
  'zaosė',
  'zaugė',
  'zaukė',
  'zauna',
  'zaunė',
  'zelma',
  'zelva',
  'zelvė',
  'zembė',
  'zenas',
  'zengė',
  'zimbė',
  'zirkė',
  'zirza',
  'zirza',
  'zirzė',
  'zizai',
  'zmeko',
  'znica',
  'zomša',
  'zuikė',
  'zuiti',
  'zujai',
  'zumso',
  'zunda',
  'zunit',
  'zurgė',
  'zurpa',
  'zurza',
  'zurza',
  'zurzė',
  'zvėga',
  'zykia',
  'zykti',
  'zyzia',
  'zyzlė',
  'zyzti',
  'zūkai',
  'zūzia',
  'zūzti',
  'ąselė',
  'ąsoja',
  'ąsojo',
  'ąsoti',
  'čadas',
  'čekai',
  'čepas',
  'česys',
  'čigai',
  'čivai',
  'čižai',
  'čypas',
  'čysta',
  'čaižo',
  'čaižė',
  'čekas',
  'čekis',
  'čeksi',
  'čekšt',
  'čempė',
  'čepsi',
  'čerpė',
  'čiksi',
  'čikši',
  'čikšt',
  'činkt',
  'čirkš',
  'čirpt',
  'čirpė',
  'čiuka',
  'čiukė',
  'čiupo',
  'čiupt',
  'čiuro',
  'čiuža',
  'čiužt',
  'čiūpt',
  'čiūra',
  'čiūžt',
  'čiūžė',
  'čižas',
  'čižti',
  'čybur',
  'čypia',
  'čypti',
  'čyžia',
  'čyžta',
  'čyžti',
  'čėpla',
  'ėdalė',
  'ėdikė',
  'ėdina',
  'ėdino',
  'ėdrus',
  'ėdūnė',
  'ėdžia',
  'ėjikė',
  'ėjėja',
  'ėjėjo',
  'ėjėti',
  'ėjūnė',
  'ėrena',
  'ėsena',
  'įavus',
  'įbūva',
  'įduba',
  'įdėbė',
  'įdėtė',
  'įeiga',
  'įgalo',
  'įgija',
  'įgula',
  'įkaba',
  'įkiro',
  'įkyra',
  'įlaja',
  'įlija',
  'įmoka',
  'įmonė',
  'įmotė',
  'įmova',
  'įnamė',
  'įrąža',
  'įrėža',
  'įsuka',
  'įtaka',
  'įvaža',
  'įvija',
  'įvoda',
  'įvorė',
  'šalna',
  'šapis',
  'šarkė',
  'šatės',
  'šilai',
  'šilos',
  'šimša',
  'šipai',
  'šogdė',
  'šulai',
  'šutai',
  'šušvė',
  'šėmai',
  'šaipo',
  'šaipė',
  'šakys',
  'šakės',
  'šaldo',
  'šaldė',
  'šalia',
  'šalin',
  'šalis',
  'šalna',
  'šalpa',
  'šalti',
  'šalvi',
  'šamas',
  'šapas',
  'šapsi',
  'šarka',
  'šarma',
  'šatra',
  'šaudo',
  'šaudė',
  'šaukė',
  'šaulė',
  'šauna',
  'šauti',
  'šašas',
  'šaškė',
  'šašti',
  'šefas',
  'šeima',
  'šeiva',
  'šekit',
  'šelmė',
  'šelpė',
  'šempa',
  'šenai',
  'šepti',
  'šepši',
  'šepšė',
  'šeria',
  'šernė',
  'šerpė',
  'šerti',
  'šerys',
  'šeškė',
  'šiaip',
  'šiais',
  'šiame',
  'šiedu',
  'šieji',
  'šiems',
  'šiepė',
  'šikna',
  'šikti',
  'šilas',
  'šildo',
  'šildė',
  'šilti',
  'šimpa',
  'šinti',
  'šioje',
  'šioji',
  'šiojo',
  'šioks',
  'šioms',
  'šiose',
  'šipti',
  'širdo',
  'širma',
  'širmo',
  'širmė',
  'širvė',
  'širšo',
  'širšė',
  'šisai',
  'šitai',
  'šitas',
  'šiuos',
  'šiupa',
  'šiupo',
  'šiupt',
  'šiuro',
  'šiurė',
  'šiuša',
  'šiuža',
  'šiąja',
  'šiąją',
  'šičia',
  'šiūpt',
  'šiūra',
  'šiūst',
  'šiųjų',
  'škeže',
  'škiud',
  'škiun',
  'škotė',
  'škuna',
  'šlama',
  'šlama',
  'šlapo',
  'šlavė',
  'šlekė',
  'šlept',
  'šlijo',
  'šlikė',
  'šlitė',
  'šlovė',
  'šlubo',
  'šlubt',
  'šlubė',
  'šlyja',
  'šlyti',
  'šlėkė',
  'šmiža',
  'šmėst',
  'šnara',
  'šneka',
  'šneka',
  'šnera',
  'šnipė',
  'šokas',
  'šokis',
  'šoklė',
  'šokti',
  'šokus',
  'šonas',
  'špaga',
  'špyga',
  'špūlė',
  'šukos',
  'šulas',
  'šunie',
  'šunis',
  'šuniu',
  'šunta',
  'šunys',
  'šusti',
  'šutas',
  'šutna',
  'šutnė',
  'šutra',
  'šutvė',
  'švakš',
  'švapa',
  'švara',
  'švedė',
  'švepa',
  'švist',
  'švito',
  'švyst',
  'švyti',
  'šydas',
  'šypso',
  'šyvas',
  'šyvis',
  'šąšta',
  'šėkas',
  'šėlas',
  'šėlti',
  'šėmas',
  'šėmis',
  'šėpas',
  'šėtra',
  'šįmet',
  'šįryt',
  'šįsyk',
  'šūdas',
  'šūkis',
  'šūkom',
  'šūvis',
  'ūdara',
  'ūdrai',
  'ūdrys',
  'ūbavo',
  'ūdija',
  'ūdijo',
  'ūdyti',
  'ūglis',
  'ūgėja',
  'ūgėjo',
  'ūgėti',
  'ūkana',
  'ūkavo',
  'ūksmė',
  'ūksto',
  'ūkstė',
  'ūkyti',
  'ūmavo',
  'ūmina',
  'ūmino',
  'ūmėdė',
  'ūmėja',
  'ūmėjo',
  'ūmėti',
  'ūžavo',
  'ūžina',
  'ūžino',
  'ūžmas',
  'ūžynė',
  'žakas',
  'žanas',
  'žarai',
  'žardė',
  'želva',
  'žiedė',
  'žiogė',
  'žiupa',
  'žukai',
  'žuvys',
  'žydrė',
  'žygai',
  'žygas',
  'žėkai',
  'žėpla',
  'žabas',
  'žadas',
  'žadus',
  'žagas',
  'žagia',
  'žagrė',
  'žagsi',
  'žagti',
  'žaidė',
  'žaidė',
  'žalas',
  'žalis',
  'žalti',
  'žalvė',
  'žardo',
  'žardė',
  'žarga',
  'žargo',
  'žargė',
  'žarna',
  'žavus',
  'žebrė',
  'žeidė',
  'želdo',
  'želdė',
  'želia',
  'želti',
  'želvė',
  'žemas',
  'žembi',
  'žembė',
  'žemyn',
  'žemys',
  'žengė',
  'žergė',
  'žeria',
  'žerti',
  'žibsi',
  'žibus',
  'žiebė',
  'žiedė',
  'žiema',
  'žiemė',
  'žievė',
  'žigas',
  'žilas',
  'žildo',
  'žildė',
  'žilis',
  'žilpo',
  'žilti',
  'žinda',
  'žindo',
  'žindė',
  'žingt',
  'žinia',
  'žiodo',
  'žiodė',
  'žioja',
  'žioji',
  'žiojo',
  'žiopt',
  'žiora',
  'žioti',
  'žiova',
  'žiras',
  'žirgt',
  'žirti',
  'žiuri',
  'žiužė',
  'žiūra',
  'žiūri',
  'žlebo',
  'žlega',
  'žlegt',
  'žlept',
  'žlibė',
  'žlugo',
  'žlėgė',
  'žlėja',
  'žmona',
  'žmoni',
  'žneko',
  'žnekt',
  'žnybt',
  'žnybė',
  'žodis',
  'žuvis',
  'žvaga',
  'žvakė',
  'žveja',
  'žvejė',
  'žvikė',
  'žvyrė',
  'žybsi',
  'žybso',
  'žydas',
  'žąsis',
  'žėbia',
  'žėbti',
  'žįsti',
  'žūklė',
  'žūsta',
  'žūtis',
  'žydra',
  'žydrė',
  'žygis',
  'žylis',
  'žymuo',
  'žymus',
  'žynys',
  'žysta',
  'žysti',
//variations
  'agnos',
  'aidos',
  'aidos',
  'albos',
  'aldos',
  'alfos',
  'alfos',
  'algos',
  'alkos',
  'alkos',
  'almos',
  'almos',
  'almos',
  'alnos',
  'alvos',
  'angos',
  'ankos',
  'antos',
  'arbos',
  'ardos',
  'arfos',
  'arios',
  'arkos',
  'aslos',
  'astos',
  'augos',
  'aukos',
  'aunos',
  'auros',
  'auros',
  'balos',
  'baros',
  'bedos',
  'benos',
  'besos',
  'betos',
  'bobos',
  'bojos',
  'bonos',
  'bubos',
  'budos',
  'buzos',
  'bylos',
  'byros',
  'bąlos',
  'bėdos',
  'bėgos',
  'bėgos',
  'bėros',
  'būdos',
  'būdos',
  'būnos',
  'būnos',
  'būvos',
  'cibos',
  'cūgos',
  'dagos',
  'damos',
  'danos',
  'datos',
  'dedos',
  'degos',
  'dejos',
  'deros',
  'dinos',
  'domos',
  'donos',
  'doros',
  'doros',
  'dubos',
  'dujos',
  'dujos',
  'dylos',
  'dyžos',
  'dėkos',
  'dūdos',
  'dūdos',
  'dūjos',
  'dūlos',
  'edmos',
  'eigos',
  'einos',
  'elmos',
  'eltos',
  'elvos',
  'ernos',
  'ertos',
  'erzos',
  'ežios',
  'fidos',
  'figos',
  'fugos',
  'fėjos',
  'gagos',
  'gagos',
  'gajos',
  'gajos',
  'gakos',
  'gamos',
  'ganos',
  'gebos',
  'gebos',
  'gemos',
  'genos',
  'gijos',
  'ginos',
  'ginos',
  'giros',
  'gitos',
  'gizos',
  'godos',
  'godos',
  'gožos',
  'gubos',
  'gugos',
  'gugos',
  'gujos',
  'gulos',
  'gumos',
  'guros',
  'gutos',
  'gužos',
  'gyjos',
  'gėdos',
  'gėlos',
  'gūros',
  'gūros',
  'hagos',
  'ienos',
  'ievos',
  'ievos',
  'ilmos',
  'ingos',
  'irios',
  'irmos',
  'javos',
  'jogos',
  'jojos',
  'jojos',
  'jonos',
  'judos',
  'jukos',
  'jutos',
  'jąjos',
  'jėgos',
  'jūros',
  'jūros',
  'kabos',
  'kabos',
  'kados',
  'kalos',
  'kanos',
  'kapos',
  'kasos',
  'kasos',
  'katos',
  'kavos',
  'kedos',
  'kenos',
  'kepos',
  'ketos',
  'kežos',
  'kitos',
  'kišos',
  'kojos',
  'komos',
  'kopos',
  'kovos',
  'kubos',
  'kukos',
  'kunos',
  'kupos',
  'kužos',
  'kylos',
  'kyšos',
  'kąros',
  'kęros',
  'kūmos',
  'kūpos',
  'labos',
  'lajos',
  'lakos',
  'lakos',
  'lalos',
  'lamos',
  'lavos',
  'lašos',
  'lemos',
  'lenos',
  'lesos',
  'letos',
  'levos',
  'ligos',
  'linos',
  'lipos',
  'lipos',
  'liros',
  'lizos',
  'lojos',
  'lomos',
  'lomos',
  'loros',
  'lovos',
  'lupos',
  'lupos',
  'lygos',
  'lyjos',
  'lyros',
  'lytos',
  'lytos',
  'lėjos',
  'lūpos',
  'lūžos',
  'mados',
  'magos',
  'majos',
  'malos',
  'mamos',
  'manos',
  'medos',
  'mekos',
  'menos',
  'metos',
  'minos',
  'minos',
  'misos',
  'mišos',
  'mojos',
  'mokos',
  'mokos',
  'movos',
  'mošos',
  'mošos',
  'mulos',
  'mušos',
  'mygos',
  'myžos',
  'mėsos',
  'mėtos',
  'mėtos',
  'mūros',
  'mūzos',
  'mūšos',
  'nagos',
  'nanos',
  'natos',
  'natos',
  'navos',
  'našos',
  'nesos',
  'nevos',
  'nešos',
  'nicos',
  'nidos',
  'ninos',
  'nišos',
  'nonos',
  'noros',
  'notos',
  'novos',
  'nykos',
  'nyros',
  'nėros',
  'oktos',
  'olgos',
  'olios',
  'ordos',
  'ošios',
  'ožkos',
  'palos',
  'panos',
  'paros',
  'pašos',
  'pešos',
  'picos',
  'pilos',
  'pinos',
  'popos',
  'poros',
  'pozos',
  'pumos',
  'pupos',
  'putos',
  'pykos',
  'pylos',
  'pėdos',
  'pėros',
  'pūgos',
  'pūros',
  'pūvos',
  'rajos',
  'rakos',
  'ramos',
  'rasos',
  'rasos',
  'ratos',
  'rašos',
  'redos',
  'regos',
  'ribos',
  'ribos',
  'ridos',
  'ridos',
  'rimos',
  'ritos',
  'ritos',
  'rišos',
  'rodos',
  'romos',
  'rujos',
  'runos',
  'rusos',
  'rygos',
  'ryjos',
  'rylos',
  'rymos',
  'rėdos',
  'rėjos',
  'rėvos',
  'rėvos',
  'rėžos',
  'rūdos',
  'rūdos',
  'rūpos',
  'rūtos',
  'rūtos',
  'sagos',
  'salos',
  'salos',
  'savos',
  'sedos',
  'segos',
  'sekos',
  'sekos',
  'sigos',
  'sijos',
  'simos',
  'sodos',
  'sodos',
  'sofos',
  'sofos',
  'sojos',
  'sopos',
  'soros',
  'sukos',
  'sulos',
  'sumos',
  'supos',
  'syjos',
  'sąlos',
  'sėdos',
  'sėjos',
  'sėjos',
  'sėtos',
  'tados',
  'taros',
  'tekos',
  'temos',
  'tenos',
  'tepos',
  'tetos',
  'težos',
  'tinos',
  'togos',
  'tomos',
  'tonos',
  'tujos',
  'tykos',
  'tylos',
  'tylos',
  'tynos',
  'tąjos',
  'tąsos',
  'tėros',
  'tįsos',
  'tūbos',
  'tūtos',
  'ulbos',
  'uldos',
  'uogos',
  'uolos',
  'uosos',
  'urdos',
  'urnos',
  'ušnos',
  'vados',
  'vagos',
  'vajos',
  'vapos',
  'vapos',
  'vatos',
  'vazos',
  'vedos',
  'vejos',
  'vejos',
  'venos',
  'venos',
  'vepos',
  'veros',
  'vešos',
  'vežos',
  'vidos',
  'vijos',
  'vikos',
  'vilos',
  'visos',
  'vitos',
  'vizos',
  'voros',
  'vydos',
  'vygos',
  'vymos',
  'vytos',
  'vyžos',
  'vėsos',
  'yžtos',
  'zenos',
  'zinos',
  'zitos',
  'zonos',
  'zujos',
  'zylos',
  'čežos',
  'ėdros',
  'šilos',
  'šonos',
  'šyšos',
  'šėtos',
  'šakos',
  'šažos',
  'šikos',
  'šinos',
  'šitos',
  'šokos',
  'šovos',
  'šuvos',
  'šylos',
  'šąlos',
  'šėlos',
  'šėpos',
  'šūkos',
  'ūbios',
  'ūdros',
  'ūkios',
  'ūžios',
  'žanos',
  'žaros',
  'žibos',
  'žados',
  'žalos',
  'žaros',
  'žavos',
  'žibos',
  'žylos',
  'žymos',
  'žyros',
  'žąlos',
  'žūvos',
  'išaks',
  'išars',
  'išars',
  'išaus',
  'išbos',
  'išbus',
  'išdės',
  'išeis',
  'išgys',
  'išims',
  'išpis',
  'iširs',
  'išižs',
  'išjos',
  'išjos',
  'išjus',
  'išlos',
  'išlys',
  'išmos',
  'išošs',
  'išpas',
  'išpus',
  'išpūs',
  'išrys',
  'išsys',
  'išsės',
  'išuis',
  'išvys',
  'išėss',
  'išūbs',
  'išūks',
  'išūžs',
  'išžus',
  'abita',
  'Adoma',
  'agara',
  'agata',
  'Agata',
  'ajera',
  'akyla',
  'akyta',
  'Alana',
  'alava',
  'alūna',
  'amala',
  'amara',
  'amata',
  'amūra',
  'Amūra',
  'andra',
  'angla',
  'anoda',
  'antra',
  'anūka',
  'apava',
  'apsta',
  'araba',
  'arija',
  'arima',
  'arkla',
  'Arėja',
  'Arūna',
  'asila',
  'asira',
  'astra',
  'atola',
  'atoma',
  'auksa',
  'Ausla',
  'avala',
  'avara',
  'avina',
  'avuka',
  'azota',
  'ašuta',
  'ažūra',
  'Bacha',
  'baida',
  'baisa',
  'baita',
  'balda',
  'balna',
  'balna',
  'balsa',
  'balta',
  'Banga',
  'banka',
  'banta',
  'barda',
  'Barta',
  'barta',
  'baska',
  'bauba',
  'Beata',
  'bebra',
  'beica',
  'belga',
  'berna',
  'berža',
  'Berža',
  'binta',
  'biura',
  'bizna',
  'blefa',
  'bloga',
  'bloka',
  'blyna',
  'boksa',
  'bomža',
  'borta',
  'brasa',
  'brika',
  'brita',
  'briza',
  'broka',
  'broma',
  'Bruža',
  'bryza',
  'brūža',
  'buola',
  'buoma',
  'burta',
  'būgna',
  'būsta',
  'cecha',
  'centa',
  'cenza',
  'chama',
  'chana',
  'cheka',
  'chora',
  'ciana',
  'cikla',
  'cinka',
  'cirka',
  'daiga',
  'darba',
  'Darga',
  'darža',
  'Dauga',
  'dažna',
  'degla',
  'degla',
  'delna',
  'dieda',
  'diega',
  'dieva',
  'dieza',
  'Diksa',
  'dinga',
  'dioda',
  'dirža',
  'diska',
  'drapa',
  'dryža',
  'drūta',
  'dueta',
  'dugna',
  'duola',
  'durna',
  'Dvara',
  'dvara',
  'dveja',
  'dvoka',
  'dvyla',
  'dvyna',
  'Dzida',
  'dzūka',
  'dėkla',
  'dūzga',
  'džema',
  'džina',
  'džipa',
  'Egila',
  'eisma',
  'Elada',
  'Elida',
  'Eliga',
  'elita',
  'elnia',
  'Emila',
  'emyra',
  'Erdma',
  'erdva',
  'Erika',
  'etapa',
  'etata',
  'ežera',
  'faila',
  'faksa',
  'fakta',
  'falša',
  'fanta',
  'farsa',
  'farša',
  'fetra',
  'filma',
  'Firda',
  'Firma',
  'flaga',
  'Flora',
  'fonda',
  'forta',
  'fraka',
  'Freda',
  'fryga',
  'fryza',
  'Gabra',
  'Gaila',
  'gaisa',
  'ganda',
  'Ganta',
  'ganta',
  'garda',
  'garsa',
  'Gauda',
  'Geida',
  'geima',
  'gelta',
  'Gerta',
  'gesta',
  'getra',
  'Gilda',
  'gimta',
  'Ginda',
  'Ginta',
  'ginča',
  'gipsa',
  'Girda',
  'girda',
  'girta',
  'glifa',
  'glita',
  'gluda',
  'gluma',
  'Glūka',
  'gnoma',
  'gobša',
  'golfa',
  'gonga',
  'gonta',
  'graba',
  'grafa',
  'grama',
  'Grata',
  'grifa',
  'Griga',
  'grima',
  'gripa',
  'griza',
  'griža',
  'groba',
  'gruža',
  'gryba',
  'gryna',
  'grėda',
  'grūda',
  'Grūta',
  'guaša',
  'gulta',
  'gumba',
  'Gumba',
  'Gunda',
  'guota',
  'gurba',
  'Gurna',
  'gurna',
  'Gusta',
  'Gvida',
  'harda',
  'herba',
  'herca',
  'Herma',
  'hiata',
  'himna',
  'hosta',
  'ibera',
  'ibisa',
  'iešma',
  'ilyra',
  'Imina',
  'Iraka',
  'Irana',
  'irisa',
  'irkla',
  'itala',
  'išaka',
  'išija',
  'išira',
  'jamba',
  'jarda',
  'jauda',
  'jauka',
  'jauna',
  'Jezda',
  'junga',
  'juoda',
  'juoka',
  'Juoza',
  'Justa',
  'jąsia',
  'kadra',
  'kaifa',
  'kaima',
  'Kaira',
  'kaiša',
  'kakla',
  'Kalna',
  'kalna',
  'kalta',
  'kalta',
  'kampa',
  'Kanta',
  'kanta',
  'kapsa',
  'kapša',
  'karda',
  'karpa',
  'karta',
  'Kasta',
  'katra',
  'kauka',
  'kaula',
  'Kauna',
  'kaupa',
  'kauša',
  'kašta',
  'kažka',
  'kedra',
  'keksa',
  'kelia',
  'kelma',
  'Kelna',
  'kelta',
  'kelta',
  'kemsa',
  'kerša',
  'kiema',
  'kieta',
  'kilta',
  'Kinta',
  'Kipra',
  'kipša',
  'kitka',
  'kiuža',
  'klana',
  'Klara',
  'Klema',
  'Kleta',
  'kleva',
  'kliba',
  'kliša',
  'kloda',
  'klona',
  'kluba',
  'klyna',
  'kmyna',
  'knata',
  'koksa',
  'kompa',
  'Konga',
  'korta',
  'Kosta',
  'Kozma',
  'kraba',
  'kraga',
  'krana',
  'krapa',
  'krema',
  'krepa',
  'krija',
  'Kriza',
  'kriza',
  'kroka',
  'krosa',
  'Kryma',
  'krūma',
  'kuina',
  'kulna',
  'kulta',
  'kuoda',
  'kuola',
  'kuora',
  'kurda',
  'kuria',
  'kursa',
  'kurta',
  'kvapa',
  'kvita',
  'kvėša',
  'kėsla',
  'kėsta',
  'kęsma',
  'Kęsta',
  'laiba',
  'laida',
  'laika',
  'laiva',
  'langa',
  'lanka',
  'Laosa',
  'Larga',
  'latra',
  'Lauka',
  'lauka',
  'lauka',
  'Laura',
  'laura',
  'lauža',
  'Lauža',
  'leina',
  'Lemba',
  'lemta',
  'lenka',
  'Leona',
  'lieka',
  'liesa',
  'lifta',
  'limba',
  'linča',
  'liosa',
  'litra',
  'Liuda',
  'liuka',
  'lizda',
  'liūga',
  'liūna',
  'liūta',
  'lorda',
  'luita',
  'lukša',
  'luoba',
  'luoma',
  'luota',
  'luoša',
  'lusta',
  'Magna',
  'maina',
  'maiša',
  'Maksa',
  'malka',
  'Manta',
  'marga',
  'marga',
  'Marka',
  'Marsa',
  'marša',
  'masta',
  'mauka',
  'mauma',
  'Maura',
  'maura',
  'mazga',
  'melda',
  'menka',
  'metra',
  'miega',
  'miela',
  'miela',
  'mieta',
  'migša',
  'Minda',
  'Minta',
  'miška',
  'mopsa',
  'mosta',
  'muila',
  'muita',
  'mėšla',
  'narsa',
  'Narta',
  'narta',
  'narva',
  'narša',
  'Nasta',
  'nauja',
  'negra',
  'nenca',
  'neona',
  'nerva',
  'nieka',
  'nieža',
  'nimba',
  'Norta',
  'nuoda',
  'nuoga',
  'ofisa',
  'Olava',
  'Omana',
  'omara',
  'opala',
  'opėta',
  'oruma',
  'Otona',
  'ovala',
  'ozona',
  'paika',
  'paira',
  'Paksa',
  'pakta',
  'palta',
  'palva',
  'palša',
  'panta',
  'parka',
  'parša',
  'pašta',
  'pelna',
  'pensa',
  'perla',
  'persa',
  'Petra',
  'piena',
  'pikta',
  'pilka',
  'pilna',
  'pilva',
  'pirma',
  'pirsa',
  'plana',
  'pleda',
  'pleka',
  'plika',
  'plona',
  'plota',
  'plova',
  'plyna',
  'plūga',
  'poeta',
  'porta',
  'posma',
  'posta',
  'prada',
  'Prana',
  'presa',
  'Prima',
  'priza',
  'prosa',
  'prota',
  'prūda',
  'prūsa',
  'pulka',
  'pulsa',
  'pulta',
  'punda',
  'punša',
  'puoda',
  'Puoža',
  'purva',
  'pusta',
  'puška',
  'pūsta',
  'pūzra',
  'Radva',
  'raiba',
  'raina',
  'raita',
  'raiša',
  'raiža',
  'rakta',
  'Ralfa',
  'randa',
  'ranga',
  'ranta',
  'rapsa',
  'rauda',
  'rauga',
  'rauta',
  'Razma',
  'rašta',
  'reida',
  'reisa',
  'reiva',
  'resva',
  'rieta',
  'rieša',
  'rikta',
  'rimba',
  'rimta',
  'Rimta',
  'ringa',
  'ripsa',
  'rista',
  'ritma',
  'romba',
  'ruima',
  'rumba',
  'runga',
  'ruoža',
  'rusva',
  'Ryška',
  'ryžta',
  'ryžta',
  'rąsta',
  'rėžta',
  'saika',
  'saita',
  'saksa',
  'salpa',
  'Salva',
  'sanda',
  'sapna',
  'sarga',
  'sarta',
  'sauga',
  'sausa',
  'seifa',
  'seima',
  'seksa',
  'Selma',
  'semba',
  'serba',
  'sidra',
  'sieka',
  'sieta',
  'Silva',
  'Simna',
  'siuta',
  'siūla',
  'skapa',
  'Skira',
  'skita',
  'skuta',
  'skyda',
  'slava',
  'slėga',
  'smaka',
  'smoga',
  'snapa',
  'snoba',
  'snuča',
  'sodna',
  'solda',
  'sorba',
  'sosta',
  'spama',
  'spara',
  'staba',
  'stala',
  'stapa',
  'stara',
  'staža',
  'steka',
  'Stepa',
  'stoga',
  'stora',
  'stota',
  'stova',
  'sukta',
  'suola',
  'svara',
  'sveba',
  'svota',
  'sėbra',
  'taksa',
  'takta',
  'talka',
  'tanka',
  'tarna',
  'tarpa',
  'tauka',
  'Taura',
  'taura',
  'Tauta',
  'taška',
  'tempa',
  'tenta',
  'testa',
  'tigra',
  'tikra',
  'tilta',
  'tinka',
  'titra',
  'torta',
  'tosta',
  'Traka',
  'traka',
  'trala',
  'trana',
  'trapa',
  'treka',
  'tropa',
  'trosa',
  'truma',
  'tryna',
  'tunta',
  'turka',
  'turta',
  'tvana',
  'tąsia',
  'tūžma',
  'ubaga',
  'ulona',
  'unita',
  'uoksa',
  'uosta',
  'upyna',
  'upėta',
  'Urala',
  'urana',
  'urėda',
  'užara',
  'Vacla',
  'Vaida',
  'vaida',
  'Vaiga',
  'vaika',
  'vaira',
  'vaita',
  'Valda',
  'valka',
  'valsa',
  'vanta',
  'varda',
  'varga',
  'varma',
  'varna',
  'varpa',
  'varža',
  'vaška',
  'vebla',
  'veida',
  'velta',
  'verga',
  'verta',
  'viena',
  'vieša',
  'Vikta',
  'vilka',
  'vilta',
  'vilta',
  'Vinca',
  'vinta',
  'virba',
  'Virga',
  'viska',
  'vista',
  'vižla',
  'Vlada',
  'volta',
  'vysta',
  'vėpla',
  'vėzda',
  'yzopa',
  'zamša',
  'Zayra',
  'Zbara',
  'zebra',
  'Zelma',
  'Zigma',
  'zlota',
  'zonda',
  'ąsota',
  'činša',
  'čirva',
  'ėdala',
  'ėdama',
  'ėdika',
  'ėdima',
  'ėdūna',
  'ėjika',
  'ėjūna',
  'ėmėja',
  'įdaga',
  'įdara',
  'įdura',
  'įkala',
  'įkypa',
  'įnara',
  'įnaša',
  'įpina',
  'įraša',
  'įsaga',
  'įsaka',
  'įsuka',
  'įvada',
  'įvara',
  'įvija',
  'įšala',
  'įšova',
  'įžada',
  'šacha',
  'šaipa',
  'šalma',
  'šalta',
  'šansa',
  'šarma',
  'šarva',
  'šarša',
  'šarža',
  'šatra',
  'šauta',
  'šeika',
  'šelfa',
  'šerna',
  'šeška',
  'šešta',
  'šiena',
  'šifra',
  'šilka',
  'šilta',
  'šimta',
  'širma',
  'širva',
  'škica',
  'škota',
  'šlaka',
  'šlama',
  'šlifa',
  'šluba',
  'šlyna',
  'šlyva',
  'šmota',
  'šnipa',
  'špata',
  'špica',
  'špoka',
  'šposa',
  'šrata',
  'štaba',
  'štata',
  'šuora',
  'šveda',
  'švina',
  'ūkana',
  'Žamba',
  'žabta',
  'žaiba',
  'žaida',
  'žalia',
  'žamba',
  'žanda',
  'žanra',
  'žarda',
  'žasta',
  'žebra',
  'želva',
  'ženta',
  'žieda',
  'žinda',
  'žioga',
  'žirga',
  'žiūra',
  'žliba',
  'žveja',
  'žvyna',
  'žvyra',
  'žydra',
  'aidės',
  'aižys',
  'aižės',
  'akins',
  'aklės',
  'akuos',
  'alins',
  'alios',
  'alkės',
  'almės',
  'alpės',
  'aluos',
  'amsės',
  'ankss',
  'anuos',
  'ardys',
  'arens',
  'arkys',
  'artės',
  'aukos',
  'aušės',
  'badys',
  'badės',
  'baigs',
  'bakos',
  'bakys',
  'baubs',
  'bauss',
  'belss',
  'bijos',
  'bimbs',
  'birbs',
  'bizys',
  'bjurs',
  'blius',
  'blogs',
  'bluks',
  'blėss',
  'blęss',
  'blįss',
  'bobės',
  'bodės',
  'braus',
  'briss',
  'bruks',
  'brėžs',
  'bręss',
  'brįss',
  'brūzs',
  'bubys',
  'bubės',
  'budės',
  'budės',
  'bujos',
  'burbs',
  'burks',
  'buvos',
  'buzos',
  'bygzs',
  'bylos',
  'byrės',
  'bėdos',
  'bėgės',
  'būvės',
  'cirps',
  'dabos',
  'dagos',
  'dagės',
  'dalys',
  'dargs',
  'darys',
  'daubs',
  'daužs',
  'dažys',
  'delbs',
  'delss',
  'dengs',
  'dergs',
  'derės',
  'didės',
  'diegs',
  'dings',
  'dirbs',
  'dirgs',
  'diržs',
  'domės',
  'doros',
  'dorės',
  'dribs',
  'driks',
  'drožs',
  'dryks',
  'drėbs',
  'drėgs',
  'drėks',
  'drįss',
  'drūss',
  'dujės',
  'dulks',
  'dulss',
  'duobs',
  'dvaks',
  'dviss',
  'dvoks',
  'dvėss',
  'dygės',
  'dykės',
  'dyros',
  'dyrės',
  'dėkos',
  'dėmės',
  'dėmės',
  'dėvės',
  'dūgzs',
  'dūlys',
  'dūlės',
  'dūmos',
  'dūmys',
  'dūmys',
  'dūsės',
  'džius',
  'eižės',
  'erdės',
  'erzės',
  'gagės',
  'gaišs',
  'galės',
  'ganys',
  'ganės',
  'gargs',
  'garės',
  'gaubs',
  'gauss',
  'gaužs',
  'gavės',
  'gebės',
  'gedės',
  'geibs',
  'geiss',
  'gelss',
  'genės',
  'gerbs',
  'gerės',
  'giežs',
  'gilės',
  'girss',
  'gižės',
  'glebs',
  'glers',
  'gležs',
  'glies',
  'gliss',
  'globs',
  'glums',
  'gluss',
  'glėbs',
  'gnybs',
  'gobės',
  'godos',
  'godės',
  'gožės',
  'gries',
  'griss',
  'grius',
  'grobs',
  'grobs',
  'grubs',
  'grums',
  'gruss',
  'gruzs',
  'grėbs',
  'grėss',
  'gręss',
  'gręžs',
  'grįss',
  'grįžs',
  'grūss',
  'gubos',
  'gugės',
  'gulės',
  'gumbs',
  'guoss',
  'gurbs',
  'gurgs',
  'gurės',
  'gužės',
  'gvers',
  'gydys',
  'gyvės',
  'gėdys',
  'gėdės',
  'gėrės',
  'gūmos',
  'gūžos',
  'gūžės',
  'ilgės',
  'ilsės',
  'ingzs',
  'inkšs',
  'ižins',
  'jaugs',
  'jauks',
  'jauss',
  'jodys',
  'jovys',
  'judės',
  'jungs',
  'junks',
  'juoks',
  'juoss',
  'jutės',
  'kabos',
  'kabės',
  'kaips',
  'kaiss',
  'kaišs',
  'kalės',
  'kapos',
  'karks',
  'karos',
  'karss',
  'karės',
  'karšs',
  'kasos',
  'kasys',
  'katės',
  'kauks',
  'kaups',
  'kaušs',
  'kedės',
  'keiks',
  'keips',
  'keiss',
  'kekės',
  'kemšs',
  'kenks',
  'kerbs',
  'kergs',
  'keros',
  'kerps',
  'kerės',
  'ketės',
  'kežės',
  'kilos',
  'kimšs',
  'kiožs',
  'kirks',
  'kirps',
  'kirss',
  'kiršs',
  'kitės',
  'kiurs',
  'kiužs',
  'klebs',
  'kleks',
  'klers',
  'kless',
  'klibs',
  'klirs',
  'klišs',
  'klius',
  'klups',
  'kluss',
  'klyks',
  'klyps',
  'klyss',
  'klėss',
  'knabs',
  'knebs',
  'knežs',
  'knibs',
  'knies',
  'kniss',
  'knyks',
  'korys',
  'korės',
  'kosės',
  'kovos',
  'krags',
  'kraus',
  'kreks',
  'krešs',
  'kriks',
  'kriss',
  'krups',
  'krušs',
  'kryks',
  'kryps',
  'krėss',
  'krėss',
  'krūps',
  'kuiss',
  'kukės',
  'kuops',
  'kurks',
  'kurps',
  'kurss',
  'kužės',
  'kvips',
  'kvoss',
  'kvyks',
  'kvėps',
  'kvėšs',
  'kybos',
  'kylės',
  'kyrės',
  'kyšos',
  'kyšės',
  'kėdos',
  'kėsos',
  'kėtos',
  'kūlės',
  'kūnys',
  'kūnės',
  'kūpės',
  'lalės',
  'lapos',
  'lasys',
  'lauks',
  'laups',
  'laužs',
  'lavės',
  'lašės',
  'ledys',
  'ledės',
  'leips',
  'leiss',
  'lenks',
  'liaus',
  'lieps',
  'liess',
  'liežs',
  'ligos',
  'links',
  'lipys',
  'liūss',
  'lodys',
  'lopys',
  'lubos',
  'luošs',
  'lydys',
  'lydės',
  'lygės',
  'lykys',
  'lynos',
  'lypės',
  'lytės',
  'lėtės',
  'lūkės',
  'lūžės',
  'magės',
  'makos',
  'malšs',
  'manys',
  'margs',
  'matys',
  'mauks',
  'mauss',
  'mažės',
  'medės',
  'megzs',
  'meišs',
  'melss',
  'melžs',
  'menks',
  'merks',
  'metės',
  'miešs',
  'migzs',
  'milžs',
  'minės',
  'mirks',
  'miršs',
  'mišys',
  'mokys',
  'mokės',
  'molės',
  'movės',
  'murks',
  'mylės',
  'mėtys',
  'mūrys',
  'mūsos',
  'mūsys',
  'mūvės',
  'neigs',
  'neršs',
  'niaus',
  'niežs',
  'nigzs',
  'nirss',
  'niršs',
  'niuks',
  'niurs',
  'niūks',
  'norės',
  'novys',
  'nyros',
  'nyrės',
  'ošins',
  'paiks',
  'palšs',
  'pamps',
  'pamės',
  'parps',
  'pašys',
  'peiks',
  'pelys',
  'pelės',
  'penės',
  'perės',
  'peršs',
  'pieps',
  'piešs',
  'pigės',
  'pijos',
  'pilks',
  'pinos',
  'pirks',
  'pirps',
  'piršs',
  'pjaus',
  'plaks',
  'plaus',
  'plers',
  'pliks',
  'pliss',
  'pluks',
  'plušs',
  'plyšs',
  'plėks',
  'plėss',
  'plėšs',
  'plūks',
  'plūss',
  'ponės',
  'prass',
  'pruss',
  'puiks',
  'pukšs',
  'puošs',
  'purps',
  'purss',
  'purės',
  'putos',
  'putos',
  'pydys',
  'pynės',
  'pėrys',
  'pūdys',
  'pūgos',
  'pūkys',
  'pūkšs',
  'ragės',
  'raibs',
  'raišs',
  'rasos',
  'raugs',
  'rauks',
  'rauss',
  'ravės',
  'rašys',
  'regzs',
  'regės',
  'reiks',
  'remžs',
  'rengs',
  'retės',
  'ribos',
  'ribės',
  'ridės',
  'rieks',
  'riess',
  'rigzs',
  'rinks',
  'ritės',
  'riūks',
  'rodys',
  'romys',
  'ropos',
  'ropšs',
  'ropšs',
  'rudės',
  'rujos',
  'rumžs',
  'rungs',
  'ruops',
  'ruošs',
  'rusės',
  'rydys',
  'rykšs',
  'rymos',
  'rytės',
  'ryšės',
  'rąžys',
  'rėkšs',
  'rėžys',
  'rūdys',
  'rūgės',
  'rūkys',
  'rūpės',
  'rūsos',
  'sakys',
  'salss',
  'sauss',
  'segės',
  'senės',
  'sieks',
  'sijos',
  'silps',
  'sirgs',
  'sirps',
  'siuss',
  'siųss',
  'skars',
  'skass',
  'skels',
  'skils',
  'skins',
  'skirs',
  'skiss',
  'skobs',
  'skubs',
  'skurs',
  'skuss',
  'skyss',
  'skėss',
  'skęss',
  'skųss',
  'slops',
  'slyss',
  'slėgs',
  'slėps',
  'slūgs',
  'smegs',
  'smigs',
  'smils',
  'smogs',
  'smuks',
  'smėls',
  'snigs',
  'snūss',
  'sopės',
  'spirs',
  'spiss',
  'spurs',
  'spęss',
  'spįss',
  'srėbs',
  'stebs',
  'stigs',
  'stips',
  'stirs',
  'stoks',
  'stums',
  'stybs',
  'stygs',
  'styps',
  'stėrs',
  'sulps',
  'sulus',
  'sunks',
  'suoks',
  'suops',
  'suoss',
  'suužs',
  'svais',
  'svers',
  'svils',
  'svirs',
  'sviss',
  'syjės',
  'sėdės',
  'sėdės',
  'sūdys',
  'sūnys',
  'sūrės',
  'tamss',
  'tapos',
  'tapys',
  'tarps',
  'tauss',
  'tašys',
  'teigs',
  'teiks',
  'teiss',
  'tekės',
  'telks',
  'telžs',
  'temps',
  'tenės',
  'terps',
  'teršs',
  'tesės',
  'težės',
  'tieks',
  'tiess',
  'tikės',
  'tikšs',
  'tilps',
  'tilžs',
  'tings',
  'tirps',
  'tolės',
  'trems',
  'trešs',
  'triks',
  'trins',
  'truks',
  'trups',
  'tryps',
  'trėss',
  'tręšs',
  'trūks',
  'tulžs',
  'tuoks',
  'tupės',
  'tupės',
  'turks',
  'turss',
  'turės',
  'turšs',
  'tvers',
  'tvils',
  'tvins',
  'tykos',
  'tylės',
  'tyrės',
  'tysos',
  'tąsys',
  'tėkšs',
  'tįsos',
  'tįsės',
  'tūlos',
  'tūnos',
  'ugdys',
  'ulbės',
  'uldės',
  'unkšs',
  'uolės',
  'urdės',
  'urens',
  'urgzs',
  'urkšs',
  'usnės',
  'vadės',
  'vagos',
  'vajos',
  'valys',
  'vanos',
  'vapės',
  'vargs',
  'varys',
  'vazos',
  'vedės',
  'veiks',
  'veiss',
  'vejos',
  'velys',
  'velės',
  'vengs',
  'vepės',
  'vergs',
  'verks',
  'verps',
  'verss',
  'veržs',
  'vešės',
  'vieps',
  'vijos',
  'vilgs',
  'vilks',
  'virbs',
  'virss',
  'vožės',
  'vyrės',
  'vytės',
  'vėsės',
  'vėtys',
  'yvaus',
  'zaugs',
  'zauks',
  'zembs',
  'zengs',
  'zimbs',
  'zirks',
  'zirzs',
  'zmeks',
  'zujos',
  'zurgs',
  'zurzs',
  'čežės',
  'čirps',
  'čiups',
  'čiurs',
  'ėdins',
  'ėmios',
  'įdėbs',
  'įgals',
  'įkirs',
  'šakos',
  'šauks',
  'šažės',
  'šelps',
  'šieps',
  'širms',
  'širss',
  'širšs',
  'šiups',
  'šiurs',
  'šlaps',
  'šlies',
  'šlubs',
  'šluos',
  'šlėks',
  'šviss',
  'švęss',
  'šykšs',
  'ščius',
  'ūbaus',
  'ūkaus',
  'ūksės',
  'ūkuos',
  'ūmaus',
  'ūmins',
  'ūžaus',
  'ūžins',
  'ūžuos',
  'žabos',
  'žadės',
  'žaiss',
  'žaiss',
  'žalos',
  'žalės',
  'žavės',
  'žeiss',
  'želės',
  'žembs',
  'žemės',
  'žengs',
  'žergs',
  'žibės',
  'žiebs',
  'žiess',
  'žilps',
  'žilės',
  'žinos',
  'žlebs',
  'žlugs',
  'žlėgs',
  'žneks',
  'žnybs',
  'žolės',
  'žudys',
  'žydės',
  'žymės',
  'žėrės',
  'nuaks',
  'nuars',
  'nuars',
  'nuaus',
  'nubos',
  'nubus',
  'nudės',
  'nueis',
  'išgis',
  'nuims',
  'nuirs',
  'nuižs',
  'nujos',
  'nujos',
  'nujus',
  'nulos',
  'nulys',
  'numos',
  'nuošs',
  'nupas',
  'nupus',
  'nupūs',
  'nurys',
  'nusys',
  'nusės',
  'nuuis',
  'nuvys',
  'nuėss',
  'nuūbs',
  'nuūks',
  'nuūžs',
  'nužus',
  'actai',
  'aidai',
  'aidai',
  'aklai',
  'aktai',
  'albai',
  'aldai',
  'alfai',
  'alkai',
  'alkai',
  'almai',
  'alsai',
  'altai',
  'ansai',
  'antai',
  'ardai',
  'arnai',
  'arpai',
  'astai',
  'augai',
  'aulai',
  'autai',
  'aūlai',
  'badai',
  'bakai',
  'balai',
  'barai',
  'basai',
  'batai',
  'benai',
  'bisai',
  'bitai',
  'bodai',
  'bonai',
  'borai',
  'bosai',
  'botai',
  'bukai',
  'bukai',
  'bumai',
  'butai',
  'bėrai',
  'būdai',
  'būrai',
  'carai',
  'celai',
  'cilai',
  'cinai',
  'danai',
  'danai',
  'dažai',
  'depai',
  'dogai',
  'dokai',
  'domai',
  'dorai',
  'dorai',
  'dovai',
  'dubai',
  'dusai',
  'dušai',
  'dykai',
  'dėtai',
  'dūlai',
  'dūmai',
  'edmai',
  'egzai',
  'eidai',
  'eigai',
  'eimai',
  'einai',
  'eitai',
  'elfai',
  'elvai',
  'erdai',
  'ergai',
  'estai',
  'eurai',
  'fanai',
  'finai',
  'fonai',
  'fotai',
  'galai',
  'garai',
  'gedai',
  'genai',
  'gerai',
  'gerai',
  'getai',
  'gidai',
  'gikai',
  'ginai',
  'godai',
  'gogai',
  'gotai',
  'guzai',
  'gužai',
  'gyvai',
  'gėlai',
  'gūdai',
  'hitai',
  'hunai',
  'ignai',
  'ikrai',
  'ilgai',
  'indai',
  'inkai',
  'iždai',
  'jakai',
  'jakai',
  'jarai',
  'jasai',
  'javai',
  'jodai',
  'jogai',
  'jonai',
  'jonai',
  'jotai',
  'jotai',
  'jurai',
  'kapai',
  'karai',
  'kerai',
  'kešai',
  'kežai',
  'kinai',
  'kirai',
  'kitai',
  'kitai',
  'kodai',
  'kotai',
  'kovai',
  'kubai',
  'kurai',
  'kurai',
  'kutai',
  'kūdai',
  'kūkai',
  'kūmai',
  'kūnai',
  'labai',
  'labai',
  'ladai',
  'lakai',
  'lapai',
  'lašai',
  'lažai',
  'ledai',
  'lemai',
  'lenai',
  'letai',
  'levai',
  'linai',
  'linai',
  'litai',
  'lobai',
  'lopai',
  'lukai',
  'lynai',
  'lėnai',
  'lėnai',
  'lėtai',
  'macai',
  'magai',
  'majai',
  'makai',
  'manai',
  'marai',
  'matai',
  'matai',
  'mačai',
  'mažai',
  'medai',
  'mekai',
  'melai',
  'menai',
  'merai',
  'metai',
  'mikai',
  'milai',
  'minai',
  'mitai',
  'molai',
  'mulai',
  'musai',
  'mūrai',
  'nadai',
  'nagai',
  'namai',
  'narai',
  'natai',
  'nekai',
  'nikai',
  'nilai',
  'nitai',
  'norai',
  'padai',
  'pakai',
  'palai',
  'papai',
  'pasai',
  'patai',
  'pažai',
  'pelai',
  'penai',
  'perai',
  'pikai',
  'pinai',
  'ponai',
  'popai',
  'porai',
  'povai',
  'pučai',
  'pužai',
  'pėdai',
  'pūdai',
  'pūkai',
  'pūrai',
  'ragai',
  'ragai',
  'ramai',
  'ratai',
  'ražai',
  'redai',
  'repai',
  'retai',
  'rifai',
  'rimai',
  'rimai',
  'rokai',
  'rokai',
  'romai',
  'romai',
  'rudai',
  'rufai',
  'rusai',
  'rykai',
  'rytai',
  'rėmai',
  'rūbai',
  'rūkai',
  'rūmai',
  'sabai',
  'savai',
  'savai',
  'senai',
  'setai',
  'simai',
  'sirai',
  'sodai',
  'susai',
  'sykai',
  'tadai',
  'tajai',
  'takai',
  'tanai',
  'tavai',
  'tašai',
  'tipai',
  'tirai',
  'tisai',
  'titai',
  'tomai',
  'tomai',
  'tonai',
  'tufai',
  'tunai',
  'turai',
  'tušai',
  'tymai',
  'tyrai',
  'tyrai',
  'tėvai',
  'tūbai',
  'tūlai',
  'tūzai',
  'ugrai',
  'uodai',
  'uotai',
  'uošai',
  'urmai',
  'ursai',
  'urvai',
  'vadai',
  'valai',
  'varai',
  'vatai',
  'verai',
  'večai',
  'vidai',
  'vilai',
  'visai',
  'vitai',
  'vokai',
  'volai',
  'vorai',
  'vydai',
  'vygai',
  'vynai',
  'vyrai',
  'vytai',
  'vąšai',
  'vėjai',
  'zenai',
  'čadai',
  'čepai',
  'čypai',
  'čekai',
  'čižai',
  'šamai',
  'šapai',
  'šašai',
  'šefai',
  'šilai',
  'šitai',
  'šokai',
  'šonai',
  'šulai',
  'šutai',
  'šydai',
  'šyvai',
  'šėkai',
  'šėlai',
  'šėmai',
  'šėpai',
  'šūdai',
  'ūžmai',
  'žakai',
  'žanai',
  'žygai',
  'žabai',
  'žadai',
  'žagai',
  'žalai',
  'žemai',
  'žigai',
  'žilai',
  'žirai',
  'žydai',
  'aidys',
  'ainys',
  'airys',
  'aldys',
  'algys',
  'alkys',
  'alkys',
  'almys',
  'alvys',
  'angys',
  'antys',
  'artys',
  'arvys',
  'augys',
  'aurys',
  'ausys',
  'barys',
  'budys',
  'bukys',
  'byrys',
  'bėgys',
  'bėrys',
  'bėžys',
  'būtys',
  'būvys',
  'cerys',
  'cezys',
  'colys',
  'dalys',
  'danys',
  'dašys',
  'denys',
  'dovys',
  'dydys',
  'dygys',
  'dėtys',
  'dūkys',
  'dūlys',
  'dūrys',
  'dūžys',
  'eigys',
  'einys',
  'elmys',
  'erbys',
  'ernys',
  'felys',
  'galys',
  'gilys',
  'gobys',
  'gylys',
  'gymys',
  'gytys',
  'gyvys',
  'gėrys',
  'gūdys',
  'gūsys',
  'harys',
  'helys',
  'hobys',
  'ietys',
  'ilgys',
  'iltys',
  'imtys',
  'indys',
  'itrys',
  'išvys',
  'jomys',
  'jonys',
  'jorys',
  'jumys',
  'jūrys',
  'kalys',
  'kelys',
  'kibys',
  'kinys',
  'kokys',
  'kukys',
  'kurys',
  'kuzys',
  'kyšys',
  'kėnys',
  'kūgys',
  'kūjys',
  'kūlys',
  'kūlys',
  'litys',
  'lobys',
  'lydys',
  'lygys',
  'lytys',
  'lyžys',
  'lėkys',
  'lęšys',
  'lūšys',
  'lūžys',
  'malys',
  'medys',
  'migys',
  'mojys',
  'molys',
  'mumys',
  'myrys',
  'mėlys',
  'mėtys',
  'mūšys',
  'nacys',
  'nerys',
  'nikys',
  'nosys',
  'nulys',
  'nytys',
  'nėšys',
  'osmys',
  'pikys',
  'pilys',
  'pilys',
  'polys',
  'polys',
  'ponys',
  'purys',
  'pušys',
  'pygys',
  'pūkys',
  'pūtys',
  'radys',
  'ragys',
  'ralys',
  'regys',
  'renys',
  'rikys',
  'rodys',
  'rudys',
  'rytys',
  'ryžys',
  'rėtys',
  'rėžys',
  'rūdys',
  'rūšys',
  'senys',
  'sigys',
  'sotys',
  'suvys',
  'sykys',
  'sūkys',
  'sūrys',
  'talys',
  'tekys',
  'tobys',
  'tolys',
  'torys',
  'tošys',
  'tulys',
  'tynys',
  'tėpys',
  'tėtys',
  'tįsys',
  'tūlys',
  'tūrys',
  'tūžys',
  'ugnys',
  'uodys',
  'uosys',
  'uosys',
  'usnys',
  'užvys',
  'vagys',
  'varys',
  'važys',
  'vikys',
  'vikys',
  'vinys',
  'virys',
  'vogys',
  'votys',
  'vyrys',
  'vytys',
  'vytys',
  'vėmys',
  'vėsys',
  'čekys',
  'šapys',
  'šalys',
  'šiays',
  'šokys',
  'šunys',
  'šyvys',
  'šėmys',
  'šūkys',
  'šūvys',
  'ūglys',
  'žalys',
  'žilys',
  'žodys',
  'žuvys',
  'žygys',
  'žylys',
  'žąsys',
  'žūtys',
  'Agnės',
  'aibės',
  'Aidės',
  'ainės',
  'Airės',
  'airės',
  'aižės',
  'Aklės',
  'aklės',
  'Albės',
  'Aldės',
  'Algės',
  'Almės',
  'Alnės',
  'Alvės',
  'ardės',
  'arkės',
  'Arvės',
  'Astės',
  'audės',
  'Augės',
  'Aurės',
  'badės',
  'Barės',
  'barės',
  'basės',
  'bazės',
  'bedės',
  'Benės',
  'bigės',
  'bitės',
  'Bitės',
  'budės',
  'bukės',
  'burės',
  'Butės',
  'Bušės',
  'Bylės',
  'Bėlės',
  'bėrės',
  'bėrės',
  'būrės',
  'būrės',
  'Būtės',
  'celės',
  'Celės',
  'cibės',
  'Cilės',
  'cypės',
  'cypės',
  'cėcės',
  'Dalės',
  'Danės',
  'danės',
  'Darės',
  'darės',
  'davės',
  'dažės',
  'degės',
  'dobės',
  'dogės',
  'Domės',
  'domės',
  'Donės',
  'Dorės',
  'Dovės',
  'dozės',
  'dubės',
  'dučės',
  'dygės',
  'dynės',
  'dyrės',
  'dyžės',
  'dėdės',
  'dėlės',
  'dėmės',
  'dėžės',
  'dūlės',
  'dūmės',
  'dūrės',
  'Eglės',
  'eglės',
  'eibės',
  'Eidės',
  'Eigės',
  'eilės',
  'Eimės',
  'Einės',
  'Eisės',
  'Eitės',
  'Eivės',
  'elgės',
  'elgės',
  'elnės',
  'Elzės',
  'engės',
  'Erdės',
  'erkės',
  'esmės',
  'estės',
  'Etmės',
  'fazės',
  'Felės',
  'Fidės',
  'Filės',
  'filės',
  'finės',
  'fojės',
  'galės',
  'ganės',
  'gegės',
  'Genės',
  'gidės',
  'gilės',
  'gimės',
  'ginės',
  'Girės',
  'gobės',
  'gotės',
  'gožės',
  'gudės',
  'gugės',
  'gulės',
  'gydės',
  'gylės',
  'gylės',
  'gynės',
  'gyrės',
  'Gytės',
  'gėlės',
  'gėlės',
  'Gėlės',
  'gėrės',
  'gęšės',
  'gūžės',
  'gūžės',
  'halės',
  'Helės',
  'hunės',
  'Ignės',
  'ikrės',
  'ilgės',
  'Ilzės',
  'Ilžės',
  'indės',
  'Ingės',
  'inkės',
  'Irmės',
  'išnės',
  'Janės',
  'jodės',
  'Jogės',
  'jogės',
  'Jolės',
  'Jonės',
  'jovės',
  'Julės',
  'Juzės',
  'Jūrės',
  'kabės',
  'kalės',
  'kalės',
  'kasės',
  'katės',
  'Kazės',
  'kekės',
  'kepės',
  'kerės',
  'kinės',
  'kopės',
  'kopės',
  'korės',
  'kovės',
  'košės',
  'košės',
  'kupės',
  'kuzės',
  'kėdės',
  'kėlės',
  'kėtės',
  'kūkės',
  'kūlės',
  'kūlės',
  'kūrės',
  'kūtės',
  'lakės',
  'lapės',
  'lasės',
  'lesės',
  'Lilės',
  'Linės',
  'lipės',
  'ližės',
  'lodės',
  'lokės',
  'lopės',
  'lošės',
  'ložės',
  'Lucės',
  'lydės',
  'lygės',
  'lysės',
  'lėkės',
  'lėlės',
  'lėmės',
  'magės',
  'Magės',
  'majės',
  'malės',
  'manės',
  'Manės',
  'Marės',
  'masės',
  'matės',
  'menės',
  'merės',
  'metės',
  'Milės',
  'minės',
  'Minės',
  'mirės',
  'mišės',
  'mokės',
  'molės',
  'motės',
  'movės',
  'Mozės',
  'mugės',
  'musės',
  'Musės',
  'mušės',
  'mykės',
  'Mylės',
  'mynės',
  'mynės',
  'mėmės',
  'mėsės',
  'mėtės',
  'mėžės',
  'mūkės',
  'nacės',
  'narės',
  'Natės',
  'Nelės',
  'Nerės',
  'nešės',
  'nėgės',
  'nėrės',
  'oazės',
  'ošlės',
  'Palės',
  'pašės',
  'pelės',
  'pešės',
  'Pimės',
  'Polės',
  'povės',
  'purės',
  'pusės',
  'putės',
  'pydės',
  'pylės',
  'pynės',
  'pynės',
  'pypės',
  'pėdės',
  'pėrės',
  'pūdės',
  'pūkės',
  'pūtės',
  'ragės',
  'rakės',
  'Ramės',
  'rasės',
  'Razės',
  'rašės',
  'relės',
  'Renės',
  'rikės',
  'ritės',
  'rizės',
  'rodės',
  'rolės',
  'ropės',
  'rovės',
  'Rožės',
  'rožės',
  'rudės',
  'rusės',
  'rydės',
  'Rytės',
  'rąžės',
  'rėkės',
  'rėmės',
  'rėpės',
  'rėžės',
  'ręžės',
  'rūdės',
  'rūkės',
  'sagės',
  'sakės',
  'salės',
  'Salės',
  'segės',
  'segės',
  'sekės',
  'senės',
  'sesės',
  'sirės',
  'Sėlės',
  'sėmės',
  'sėmės',
  'sūdės',
  'Tadės',
  'tajės',
  'tapės',
  'tarės',
  'tašės',
  'tepės',
  'Terės',
  'tezės',
  'tižės',
  'Tulės',
  'Tušės',
  'Tužės',
  'tynės',
  'tyrės',
  'tyrės',
  'tąsės',
  'tėtės',
  'tęsės',
  'tūbės',
  'tūpės',
  'ugdės',
  'Ugnės',
  'ugrės',
  'uodės',
  'uodės',
  'uogės',
  'urbės',
  'urpės',
  'Urtės',
  'Uršės',
  'Vacės',
  'vadės',
  'vajės',
  'Valės',
  'valės',
  'valės',
  'varės',
  'vedės',
  'velės',
  'Venės',
  'Verės',
  'vežės',
  'Vilės',
  'virės',
  'vogės',
  'Vokės',
  'vokės',
  'volės',
  'vožės',
  'Vydės',
  'vylės',
  'Vytės',
  'vyžės',
  'vyžės',
  'vėlės',
  'vėlės',
  'vėmės',
  'vėpės',
  'vėrės',
  'vėrės',
  'vėtės',
  'vėžės',
  'yzgės',
  'Zabės',
  'Zenės',
  'Zosės',
  'zykės',
  'zylės',
  'zyzės',
  'zūzės',
  'Česės',
  'Čilės',
  'čekės',
  'čypės',
  'čyžės',
  'Šukės',
  'šakės',
  'šašės',
  'šilės',
  'šovės',
  'šukės',
  'šulės',
  'šyvės',
  'šėmės',
  'šėrės',
  'ūžlės',
  'Žygės',
  'žagės',
  'žalės',
  'želės',
  'žemės',
  'žibės',
  'žilės',
  'žižės',
  'žolės',
  'žudės',
  'žydės',
  'žymės',
  'žynės',
  'žėbės',
  'žėlės',
  'žėrės',
  'aidės',
  'aižys',
  'aižės',
  'akins',
  'aklės',
  'akuos',
  'alins',
  'alios',
  'alkės',
  'almės',
  'alpės',
  'aluos',
  'amsės',
  'ankss',
  'anuos',
  'ardys',
  'arens',
  'arkys',
  'artės',
  'aukos',
  'aušės',
  'badys',
  'badės',
  'baigs',
  'bakos',
  'bakys',
  'baubs',
  'bauss',
  'belss',
  'bijos',
  'bimbs',
  'birbs',
  'bizys',
  'bjurs',
  'blius',
  'blogs',
  'bluks',
  'blėss',
  'blęss',
  'blįss',
  'bobės',
  'bodės',
  'braus',
  'briss',
  'bruks',
  'brėžs',
  'bręss',
  'brįss',
  'brūzs',
  'bubys',
  'bubės',
  'budės',
  'budės',
  'bujos',
  'burbs',
  'burks',
  'buvos',
  'buzos',
  'bygzs',
  'bylos',
  'byrės',
  'bėdos',
  'bėgės',
  'būvės',
  'cirps',
  'dabos',
  'dagos',
  'dagės',
  'dalys',
  'dargs',
  'darys',
  'daubs',
  'daužs',
  'dažys',
  'delbs',
  'delss',
  'dengs',
  'dergs',
  'derės',
  'didės',
  'diegs',
  'dings',
  'dirbs',
  'dirgs',
  'diržs',
  'domės',
  'doros',
  'dorės',
  'dribs',
  'driks',
  'drožs',
  'dryks',
  'drėbs',
  'drėgs',
  'drėks',
  'drįss',
  'drūss',
  'dujės',
  'dulks',
  'dulss',
  'duobs',
  'dvaks',
  'dviss',
  'dvoks',
  'dvėss',
  'dygės',
  'dykės',
  'dyros',
  'dyrės',
  'dėkos',
  'dėmės',
  'dėmės',
  'dėvės',
  'dūgzs',
  'dūlys',
  'dūlės',
  'dūmos',
  'dūmys',
  'dūmys',
  'dūsės',
  'džius',
  'eižės',
  'erdės',
  'erzės',
  'gagės',
  'gaišs',
  'galės',
  'ganys',
  'ganės',
  'gargs',
  'garės',
  'gaubs',
  'gauss',
  'gaužs',
  'gavės',
  'gebės',
  'gedės',
  'geibs',
  'geiss',
  'gelss',
  'genės',
  'gerbs',
  'gerės',
  'giežs',
  'gilės',
  'girss',
  'gižės',
  'glebs',
  'glers',
  'gležs',
  'glies',
  'gliss',
  'globs',
  'glums',
  'gluss',
  'glėbs',
  'gnybs',
  'gobės',
  'godos',
  'godės',
  'gožės',
  'gries',
  'griss',
  'grius',
  'grobs',
  'grobs',
  'grubs',
  'grums',
  'gruss',
  'gruzs',
  'grėbs',
  'grėss',
  'gręss',
  'gręžs',
  'grįss',
  'grįžs',
  'grūss',
  'gubos',
  'gugės',
  'gulės',
  'gumbs',
  'guoss',
  'gurbs',
  'gurgs',
  'gurės',
  'gužės',
  'gvers',
  'gydys',
  'gyvės',
  'gėdys',
  'gėdės',
  'gėrės',
  'gūmos',
  'gūžos',
  'gūžės',
  'ilgės',
  'ilsės',
  'ingzs',
  'inkšs',
  'ižins',
  'jaugs',
  'jauks',
  'jauss',
  'jodys',
  'jovys',
  'judės',
  'jungs',
  'junks',
  'juoks',
  'juoss',
  'jutės',
  'kabos',
  'kabės',
  'kaips',
  'kaiss',
  'kaišs',
  'kalės',
  'kapos',
  'karks',
  'karos',
  'karss',
  'karės',
  'karšs',
  'kasos',
  'kasys',
  'katės',
  'kauks',
  'kaups',
  'kaušs',
  'kedės',
  'keiks',
  'keips',
  'keiss',
  'kekės',
  'kemšs',
  'kenks',
  'kerbs',
  'kergs',
  'keros',
  'kerps',
  'kerės',
  'ketės',
  'kežės',
  'kilos',
  'kimšs',
  'kiožs',
  'kirks',
  'kirps',
  'kirss',
  'kiršs',
  'kitės',
  'kiurs',
  'kiužs',
  'klebs',
  'kleks',
  'klers',
  'kless',
  'klibs',
  'klirs',
  'klišs',
  'klius',
  'klups',
  'kluss',
  'klyks',
  'klyps',
  'klyss',
  'klėss',
  'knabs',
  'knebs',
  'knežs',
  'knibs',
  'knies',
  'kniss',
  'knyks',
  'korys',
  'korės',
  'kosės',
  'kovos',
  'krags',
  'kraus',
  'kreks',
  'krešs',
  'kriks',
  'kriss',
  'krups',
  'krušs',
  'kryks',
  'kryps',
  'krėss',
  'krėss',
  'krūps',
  'kuiss',
  'kukės',
  'kuops',
  'kurks',
  'kurps',
  'kurss',
  'kužės',
  'kvips',
  'kvoss',
  'kvyks',
  'kvėps',
  'kvėšs',
  'kybos',
  'kylės',
  'kyrės',
  'kyšos',
  'kyšės',
  'kėdos',
  'kėsos',
  'kėtos',
  'kūlės',
  'kūnys',
  'kūnės',
  'kūpės',
  'lalės',
  'lapos',
  'lasys',
  'lauks',
  'laups',
  'laužs',
  'lavės',
  'lašės',
  'ledys',
  'ledės',
  'leips',
  'leiss',
  'lenks',
  'liaus',
  'lieps',
  'liess',
  'liežs',
  'ligos',
  'links',
  'lipys',
  'liūss',
  'lodys',
  'lopys',
  'lubos',
  'luošs',
  'lydys',
  'lydės',
  'lygės',
  'lykys',
  'lynos',
  'lypės',
  'lytės',
  'lėtės',
  'lūkės',
  'lūžės',
  'magės',
  'makos',
  'malšs',
  'manys',
  'margs',
  'matys',
  'mauks',
  'mauss',
  'mažės',
  'medės',
  'megzs',
  'meišs',
  'melss',
  'melžs',
  'menks',
  'merks',
  'metės',
  'miešs',
  'migzs',
  'milžs',
  'minės',
  'mirks',
  'miršs',
  'mišys',
  'mokys',
  'mokės',
  'molės',
  'movės',
  'murks',
  'mylės',
  'mėtys',
  'mūrys',
  'mūsos',
  'mūsys',
  'mūvės',
  'neigs',
  'neršs',
  'niaus',
  'niežs',
  'nigzs',
  'nirss',
  'niršs',
  'niuks',
  'niurs',
  'niūks',
  'norės',
  'novys',
  'nyros',
  'nyrės',
  'ošins',
  'paiks',
  'palšs',
  'pamps',
  'pamės',
  'parps',
  'pašys',
  'peiks',
  'pelys',
  'pelės',
  'penės',
  'perės',
  'peršs',
  'pieps',
  'piešs',
  'pigės',
  'pijos',
  'pilks',
  'pinos',
  'pirks',
  'pirps',
  'piršs',
  'pjaus',
  'plaks',
  'plaus',
  'plers',
  'pliks',
  'pliss',
  'pluks',
  'plušs',
  'plyšs',
  'plėks',
  'plėss',
  'plėšs',
  'plūks',
  'plūss',
  'ponės',
  'prass',
  'pruss',
  'puiks',
  'pukšs',
  'puošs',
  'purps',
  'purss',
  'purės',
  'putos',
  'putos',
  'pydys',
  'pynės',
  'pėrys',
  'pūdys',
  'pūgos',
  'pūkys',
  'pūkšs',
  'ragės',
  'raibs',
  'raišs',
  'rasos',
  'raugs',
  'rauks',
  'rauss',
  'ravės',
  'rašys',
  'regzs',
  'regės',
  'reiks',
  'remžs',
  'rengs',
  'retės',
  'ribos',
  'ribės',
  'ridės',
  'rieks',
  'riess',
  'rigzs',
  'rinks',
  'ritės',
  'riūks',
  'rodys',
  'romys',
  'ropos',
  'ropšs',
  'ropšs',
  'rudės',
  'rujos',
  'rumžs',
  'rungs',
  'ruops',
  'ruošs',
  'rusės',
  'rydys',
  'rykšs',
  'rymos',
  'rytės',
  'ryšės',
  'rąžys',
  'rėkšs',
  'rėžys',
  'rūdys',
  'rūgės',
  'rūkys',
  'rūpės',
  'rūsos',
  'sakys',
  'salss',
  'sauss',
  'segės',
  'senės',
  'sieks',
  'sijos',
  'silps',
  'sirgs',
  'sirps',
  'siuss',
  'siųss',
  'skars',
  'skass',
  'skels',
  'skils',
  'skins',
  'skirs',
  'skiss',
  'skobs',
  'skubs',
  'skurs',
  'skuss',
  'skyss',
  'skėss',
  'skęss',
  'skųss',
  'slops',
  'slyss',
  'slėgs',
  'slėps',
  'slūgs',
  'smegs',
  'smigs',
  'smils',
  'smogs',
  'smuks',
  'smėls',
  'snigs',
  'snūss',
  'sopės',
  'spirs',
  'spiss',
  'spurs',
  'spęss',
  'spįss',
  'srėbs',
  'stebs',
  'stigs',
  'stips',
  'stirs',
  'stoks',
  'stums',
  'stybs',
  'stygs',
  'styps',
  'stėrs',
  'sulps',
  'sulus',
  'sunks',
  'suoks',
  'suops',
  'suoss',
  'suužs',
  'svais',
  'svers',
  'svils',
  'svirs',
  'sviss',
  'syjės',
  'sėdės',
  'sėdės',
  'sūdys',
  'sūnys',
  'sūrės',
  'tamss',
  'tapos',
  'tapys',
  'tarps',
  'tauss',
  'tašys',
  'teigs',
  'teiks',
  'teiss',
  'tekės',
  'telks',
  'telžs',
  'temps',
  'tenės',
  'terps',
  'teršs',
  'tesės',
  'težės',
  'tieks',
  'tiess',
  'tikės',
  'tikšs',
  'tilps',
  'tilžs',
  'tings',
  'tirps',
  'tolės',
  'trems',
  'trešs',
  'triks',
  'trins',
  'truks',
  'trups',
  'tryps',
  'trėss',
  'tręšs',
  'trūks',
  'tulžs',
  'tuoks',
  'tupės',
  'tupės',
  'turks',
  'turss',
  'turės',
  'turšs',
  'tvers',
  'tvils',
  'tvins',
  'tykos',
  'tylės',
  'tyrės',
  'tysos',
  'tąsys',
  'tėkšs',
  'tįsos',
  'tįsės',
  'tūlos',
  'tūnos',
  'ugdys',
  'ulbės',
  'uldės',
  'unkšs',
  'uolės',
  'urdės',
  'urens',
  'urgzs',
  'urkšs',
  'usnės',
  'vadės',
  'vagos',
  'vajos',
  'valys',
  'vanos',
  'vapės',
  'vargs',
  'varys',
  'vazos',
  'vedės',
  'veiks',
  'veiss',
  'vejos',
  'velys',
  'velės',
  'vengs',
  'vepės',
  'vergs',
  'verks',
  'verps',
  'verss',
  'veržs',
  'vešės',
  'vieps',
  'vijos',
  'vilgs',
  'vilks',
  'virbs',
  'virss',
  'vožės',
  'vyrės',
  'vytės',
  'vėsės',
  'vėtys',
  'yvaus',
  'zaugs',
  'zauks',
  'zembs',
  'zengs',
  'zimbs',
  'zirks',
  'zirzs',
  'zmeks',
  'zujos',
  'zurgs',
  'zurzs',
  'čežės',
  'čirps',
  'čiups',
  'čiurs',
  'ėdins',
  'ėmios',
  'įdėbs',
  'įgals',
  'įkirs',
  'šakos',
  'šauks',
  'šažės',
  'šelps',
  'šieps',
  'širms',
  'širss',
  'širšs',
  'šiups',
  'šiurs',
  'šlaps',
  'šlies',
  'šlubs',
  'šluos',
  'šlėks',
  'šviss',
  'švęss',
  'šykšs',
  'ščius',
  'ūbaus',
  'ūkaus',
  'ūksės',
  'ūkuos',
  'ūmaus',
  'ūmins',
  'ūžaus',
  'ūžins',
  'ūžuos',
  'žabos',
  'žadės',
  'žaiss',
  'žaiss',
  'žalos',
  'žalės',
  'žavės',
  'žeiss',
  'želės',
  'žembs',
  'žemės',
  'žengs',
  'žergs',
  'žibės',
  'žiebs',
  'žiess',
  'žilps',
  'žilės',
  'žinos',
  'žlebs',
  'žlugs',
  'žlėgs',
  'žneks',
  'žnybs',
  'žolės',
  'žudys',
  'žydės',
  'žymės',
  'žėrės',
  'aidėk',
  'aižyk',
  'aižėk',
  'akink',
  'aklėk',
  'akuok',
  'alink',
  'aliok',
  'alkėk',
  'almėk',
  'alpėk',
  'aluok',
  'amsėk',
  'anksk',
  'anuok',
  'ardyk',
  'arenk',
  'arkyk',
  'artėk',
  'aukok',
  'aušėk',
  'badyk',
  'badėk',
  'baigk',
  'bakok',
  'bakyk',
  'baubk',
  'bausk',
  'belsk',
  'bijok',
  'bimbk',
  'birbk',
  'bizyk',
  'bjurk',
  'bliūk',
  'blogk',
  'blukk',
  'blėsk',
  'blęsk',
  'blįsk',
  'bobėk',
  'bodėk',
  'brauk',
  'brisk',
  'brukk',
  'brėžk',
  'bręsk',
  'brįsk',
  'brūzk',
  'bubyk',
  'bubėk',
  'budėk',
  'budėk',
  'bujok',
  'burbk',
  'burkk',
  'buvok',
  'buzok',
  'bygzk',
  'bylok',
  'byrėk',
  'bėdok',
  'bėgėk',
  'būvėk',
  'cirpk',
  'dabok',
  'dagok',
  'dagėk',
  'dalyk',
  'dargk',
  'daryk',
  'daubk',
  'daužk',
  'dažyk',
  'delbk',
  'delsk',
  'dengk',
  'dergk',
  'derėk',
  'didėk',
  'diegk',
  'dingk',
  'dirbk',
  'dirgk',
  'diržk',
  'domėk',
  'dorok',
  'dorėk',
  'dribk',
  'drikk',
  'drožk',
  'drykk',
  'drėbk',
  'drėgk',
  'drėkk',
  'drįsk',
  'drūsk',
  'dujėk',
  'dulkk',
  'dulsk',
  'duobk',
  'dvakk',
  'dvisk',
  'dvokk',
  'dvėsk',
  'dygėk',
  'dykėk',
  'dyrok',
  'dyrėk',
  'dėkok',
  'dėmėk',
  'dėmėk',
  'dėvėk',
  'dūgzk',
  'dūlyk',
  'dūlėk',
  'dūmok',
  'dūmyk',
  'dūmyk',
  'dūsėk',
  'džiūk',
  'eižėk',
  'erdėk',
  'erzėk',
  'gagėk',
  'gaišk',
  'galėk',
  'ganyk',
  'ganėk',
  'gargk',
  'garėk',
  'gaubk',
  'gausk',
  'gaužk',
  'gavėk',
  'gebėk',
  'gedėk',
  'geibk',
  'geisk',
  'gelsk',
  'genėk',
  'gerbk',
  'gerėk',
  'giežk',
  'gilėk',
  'girsk',
  'gižėk',
  'glebk',
  'glerk',
  'gležk',
  'gliek',
  'glisk',
  'globk',
  'glumk',
  'glusk',
  'glėbk',
  'gnybk',
  'gobėk',
  'godok',
  'godėk',
  'gožėk',
  'griek',
  'grisk',
  'griūk',
  'grobk',
  'grobk',
  'grubk',
  'grumk',
  'grusk',
  'gruzk',
  'grėbk',
  'grėsk',
  'gręsk',
  'gręžk',
  'grįsk',
  'grįžk',
  'grūsk',
  'gubok',
  'gugėk',
  'gulėk',
  'gumbk',
  'guosk',
  'gurbk',
  'gurgk',
  'gurėk',
  'gužėk',
  'gverk',
  'gydyk',
  'gyvėk',
  'gėdyk',
  'gėdėk',
  'gėrėk',
  'gūmok',
  'gūžok',
  'gūžėk',
  'ilgėk',
  'ilsėk',
  'ingzk',
  'inkšk',
  'ižink',
  'jaugk',
  'jaukk',
  'jausk',
  'jodyk',
  'jovyk',
  'judėk',
  'jungk',
  'junkk',
  'juokk',
  'juosk',
  'jutėk',
  'kabok',
  'kabėk',
  'kaipk',
  'kaisk',
  'kaišk',
  'kalėk',
  'kapok',
  'karkk',
  'karok',
  'karsk',
  'karėk',
  'karšk',
  'kasok',
  'kasyk',
  'katėk',
  'kaukk',
  'kaupk',
  'kaušk',
  'kedėk',
  'keikk',
  'keipk',
  'keisk',
  'kekėk',
  'kemšk',
  'kenkk',
  'kerbk',
  'kergk',
  'kerok',
  'kerpk',
  'kerėk',
  'ketėk',
  'kežėk',
  'kilok',
  'kimšk',
  'kiožk',
  'kirkk',
  'kirpk',
  'kirsk',
  'kiršk',
  'kitėk',
  'kiurk',
  'kiužk',
  'klebk',
  'klekk',
  'klerk',
  'klesk',
  'klibk',
  'klirk',
  'klišk',
  'kliūk',
  'klupk',
  'klusk',
  'klykk',
  'klypk',
  'klysk',
  'klėsk',
  'knabk',
  'knebk',
  'knežk',
  'knibk',
  'kniek',
  'knisk',
  'knykk',
  'koryk',
  'korėk',
  'kosėk',
  'kovok',
  'kragk',
  'krauk',
  'krekk',
  'krešk',
  'krikk',
  'krisk',
  'krupk',
  'krušk',
  'krykk',
  'krypk',
  'krėsk',
  'krėsk',
  'krūpk',
  'kuisk',
  'kukėk',
  'kuopk',
  'kurkk',
  'kurpk',
  'kursk',
  'kužėk',
  'kvipk',
  'kvosk',
  'kvykk',
  'kvėpk',
  'kvėšk',
  'kybok',
  'kylėk',
  'kyrėk',
  'kyšok',
  'kyšėk',
  'kėdok',
  'kėsok',
  'kėtok',
  'kūlėk',
  'kūnyk',
  'kūnėk',
  'kūpėk',
  'lalėk',
  'lapok',
  'lasyk',
  'laukk',
  'laupk',
  'laužk',
  'lavėk',
  'lašėk',
  'ledyk',
  'ledėk',
  'leipk',
  'leisk',
  'lenkk',
  'liauk',
  'liepk',
  'liesk',
  'liežk',
  'ligok',
  'linkk',
  'lipyk',
  'liūsk',
  'lodyk',
  'lopyk',
  'lubok',
  'luošk',
  'lydyk',
  'lydėk',
  'lygėk',
  'lykyk',
  'lynok',
  'lypėk',
  'lytėk',
  'lėtėk',
  'lūkėk',
  'lūžėk',
  'magėk',
  'makok',
  'malšk',
  'manyk',
  'margk',
  'matyk',
  'maukk',
  'mausk',
  'mažėk',
  'medėk',
  'megzk',
  'meišk',
  'melsk',
  'melžk',
  'menkk',
  'merkk',
  'metėk',
  'miešk',
  'migzk',
  'milžk',
  'minėk',
  'mirkk',
  'miršk',
  'mišyk',
  'mokyk',
  'mokėk',
  'molėk',
  'movėk',
  'murkk',
  'mylėk',
  'mėtyk',
  'mūryk',
  'mūsok',
  'mūsyk',
  'mūvėk',
  'neigk',
  'neršk',
  'niauk',
  'niežk',
  'nigzk',
  'nirsk',
  'niršk',
  'niukk',
  'niurk',
  'niūkk',
  'norėk',
  'novyk',
  'nyrok',
  'nyrėk',
  'ošink',
  'paikk',
  'palšk',
  'pampk',
  'pamėk',
  'parpk',
  'pašyk',
  'peikk',
  'pelyk',
  'pelėk',
  'penėk',
  'perėk',
  'peršk',
  'piepk',
  'piešk',
  'pigėk',
  'pijok',
  'pilkk',
  'pinok',
  'pirkk',
  'pirpk',
  'piršk',
  'pjauk',
  'plakk',
  'plauk',
  'plerk',
  'plikk',
  'plisk',
  'plukk',
  'plušk',
  'plyšk',
  'plėkk',
  'plėsk',
  'plėšk',
  'plūkk',
  'plūsk',
  'ponėk',
  'prask',
  'prusk',
  'puikk',
  'pukšk',
  'puošk',
  'purpk',
  'pursk',
  'purėk',
  'putok',
  'putok',
  'pydyk',
  'pynėk',
  'pėryk',
  'pūdyk',
  'pūgok',
  'pūkyk',
  'pūkšk',
  'ragėk',
  'raibk',
  'raišk',
  'rasok',
  'raugk',
  'raukk',
  'rausk',
  'ravėk',
  'rašyk',
  'regzk',
  'regėk',
  'reikk',
  'remžk',
  'rengk',
  'retėk',
  'ribok',
  'ribėk',
  'ridėk',
  'riekk',
  'riesk',
  'rigzk',
  'rinkk',
  'ritėk',
  'riūkk',
  'rodyk',
  'romyk',
  'ropok',
  'ropšk',
  'ropšk',
  'rudėk',
  'rujok',
  'rumžk',
  'rungk',
  'ruopk',
  'ruošk',
  'rusėk',
  'rydyk',
  'rykšk',
  'rymok',
  'rytėk',
  'ryšėk',
  'rąžyk',
  'rėkšk',
  'rėžyk',
  'rūdyk',
  'rūgėk',
  'rūkyk',
  'rūpėk',
  'rūsok',
  'sakyk',
  'salsk',
  'sausk',
  'segėk',
  'senėk',
  'siekk',
  'sijok',
  'silpk',
  'sirgk',
  'sirpk',
  'siusk',
  'siųsk',
  'skark',
  'skask',
  'skelk',
  'skilk',
  'skink',
  'skirk',
  'skisk',
  'skobk',
  'skubk',
  'skurk',
  'skusk',
  'skysk',
  'skėsk',
  'skęsk',
  'skųsk',
  'slopk',
  'slysk',
  'slėgk',
  'slėpk',
  'slūgk',
  'smegk',
  'smigk',
  'smilk',
  'smogk',
  'smukk',
  'smėlk',
  'snigk',
  'snūsk',
  'sopėk',
  'spirk',
  'spisk',
  'spurk',
  'spęsk',
  'spįsk',
  'srėbk',
  'stebk',
  'stigk',
  'stipk',
  'stirk',
  'stokk',
  'stumk',
  'stybk',
  'stygk',
  'stypk',
  'stėrk',
  'sulpk',
  'sulūk',
  'sunkk',
  'suokk',
  'suopk',
  'suosk',
  'suužk',
  'svaik',
  'sverk',
  'svilk',
  'svirk',
  'svisk',
  'syjėk',
  'sėdėk',
  'sėdėk',
  'sūdyk',
  'sūnyk',
  'sūrėk',
  'tamsk',
  'tapok',
  'tapyk',
  'tarpk',
  'tausk',
  'tašyk',
  'teigk',
  'teikk',
  'teisk',
  'tekėk',
  'telkk',
  'telžk',
  'tempk',
  'tenėk',
  'terpk',
  'teršk',
  'tesėk',
  'težėk',
  'tiekk',
  'tiesk',
  'tikėk',
  'tikšk',
  'tilpk',
  'tilžk',
  'tingk',
  'tirpk',
  'tolėk',
  'tremk',
  'trešk',
  'trikk',
  'trink',
  'trukk',
  'trupk',
  'trypk',
  'trėsk',
  'tręšk',
  'trūkk',
  'tulžk',
  'tuokk',
  'tupėk',
  'tupėk',
  'turkk',
  'tursk',
  'turėk',
  'turšk',
  'tverk',
  'tvilk',
  'tvink',
  'tykok',
  'tylėk',
  'tyrėk',
  'tysok',
  'tąsyk',
  'tėkšk',
  'tįsok',
  'tįsėk',
  'tūlok',
  'tūnok',
  'ugdyk',
  'ulbėk',
  'uldėk',
  'unkšk',
  'uolėk',
  'urdėk',
  'urenk',
  'urgzk',
  'urkšk',
  'usnėk',
  'vadėk',
  'vagok',
  'vajok',
  'valyk',
  'vanok',
  'vapėk',
  'vargk',
  'varyk',
  'vazok',
  'vedėk',
  'veikk',
  'veisk',
  'vejok',
  'velyk',
  'velėk',
  'vengk',
  'vepėk',
  'vergk',
  'verkk',
  'verpk',
  'versk',
  'veržk',
  'vešėk',
  'viepk',
  'vijok',
  'vilgk',
  'vilkk',
  'virbk',
  'virsk',
  'vožėk',
  'vyrėk',
  'vytėk',
  'vėsėk',
  'vėtyk',
  'yvauk',
  'zaugk',
  'zaukk',
  'zembk',
  'zengk',
  'zimbk',
  'zirkk',
  'zirzk',
  'zmekk',
  'zujok',
  'zurgk',
  'zurzk',
  'čežėk',
  'čirpk',
  'čiupk',
  'čiurk',
  'ėdink',
  'ėmiok',
  'įdėbk',
  'įgalk',
  'įkirk',
  'šakok',
  'šaukk',
  'šažėk',
  'šelpk',
  'šiepk',
  'širmk',
  'širsk',
  'širšk',
  'šiupk',
  'šiurk',
  'šlapk',
  'šliek',
  'šlubk',
  'šluok',
  'šlėkk',
  'švisk',
  'švęsk',
  'šykšk',
  'ščiūk',
  'ūbauk',
  'ūkauk',
  'ūksėk',
  'ūkuok',
  'ūmauk',
  'ūmink',
  'ūžauk',
  'ūžink',
  'ūžuok',
  'žabok',
  'žadėk',
  'žaisk',
  'žaisk',
  'žalok',
  'žalėk',
  'žavėk',
  'žeisk',
  'želėk',
  'žembk',
  'žemėk',
  'žengk',
  'žergk',
  'žibėk',
  'žiebk',
  'žiesk',
  'žilpk',
  'žilėk',
  'žinok',
  'žlebk',
  'žlugk',
  'žlėgk',
  'žnekk',
  'žnybk',
  'žolėk',
  'žudyk',
  'žydėk',
  'žymėk',
  'žėrėk',
  'tikai',
  'tikas',
  'užaks',
  'užars',
  'užars',
  'užaus',
  'užbos',
  'užbus',
  'uždės',
  'užeis',
  'užgys',
  'užims',
  'užpis',
  'užirs',
  'užižs',
  'užjos',
  'užjos',
  'užjus',
  'užlos',
  'užlys',
  'užmos',
  'užošs',
  'užpas',
  'užpus',
  'užrys',
  'užsys',
  'užsės',
  'užuis',
  'užvys',
  'užėss',
  'užūbs',
  'užūks',
  'užūžs',
  'užžus',
  'nuaks',
  'nuars',
  'nuars',
  'nuaus',
  'nubos',
  'nubus',
  'nudės',
  'nueis',
  'nugys',
  'nuims',
  'nupis',
  'nuirs',
  'nuižs',
  'nujos',
  'nujos',
  'nujus',
  'nulos',
  'nulys',
  'numos',
  'nuošs',
  'nupas',
  'nupus',
  'nurys',
  'nusys',
  'nusės',
  'nuuis',
  'nuvys',
  'nuėss',
  'nuūbs',
  'nuūks',
  'nuūžs',
  'nužus',
  'adyta',
  'akyta',
  'akėta',
  'alkta',
  'alpta',
  'augta',
  'austa',
  'aušta',
  'avėta',
  'avėta',
  'balta',
  'barta',
  'berta',
  'besta',
  'birta',
  'bosta',
  'bosta',
  'bukta',
  'burta',
  'busta',
  'bėgta',
  'būgta',
  'cypta',
  'degta',
  'dilta',
  'dirta',
  'dobta',
  'dubta',
  'duita',
  'dulta',
  'dumta',
  'duota',
  'durta',
  'dusta',
  'dužta',
  'dygta',
  'dykta',
  'dyžta',
  'dėrta',
  'dūkta',
  'elgta',
  'elgta',
  'engta',
  'gauta',
  'gelta',
  'gerta',
  'gesta',
  'gilta',
  'gimta',
  'ginta',
  'girta',
  'gižta',
  'gobta',
  'gožta',
  'grota',
  'gubta',
  'gugta',
  'guita',
  'gulta',
  'gurta',
  'gusta',
  'gybta',
  'gysta',
  'gūžta',
  'ilgta',
  'ilsta',
  'irzta',
  'ižyta',
  'jauta',
  'jukta',
  'justa',
  'kakta',
  'kalta',
  'karta',
  'kasta',
  'kauta',
  'kelta',
  'kepta',
  'kerta',
  'kežta',
  'kibta',
  'kilta',
  'kimta',
  'kista',
  'kišta',
  'kiūta',
  'klota',
  'knota',
  'kopta',
  'kopta',
  'košta',
  'kukta',
  'kulta',
  'kupta',
  'kurta',
  'kusta',
  'kąsta',
  'kėsta',
  'kęsta',
  'lakta',
  'lemta',
  'lepta',
  'lesta',
  'lieta',
  'likta',
  'limta',
  'lipta',
  'lipta',
  'lobta',
  'lošta',
  'lupta',
  'lygta',
  'lysta',
  'lyžta',
  'lėkta',
  'lėpta',
  'lėsta',
  'lįsta',
  'lūžta',
  'malta',
  'marta',
  'mauta',
  'mažta',
  'mesta',
  'migta',
  'minta',
  'mirta',
  'mista',
  'mišta',
  'mokta',
  'mukta',
  'murta',
  'mušta',
  'mygta',
  'mykta',
  'myžta',
  'mėgta',
  'mėlta',
  'mėžta',
  'męsta',
  'mūkta',
  'neita',
  'nerta',
  'nesta',
  'nešta',
  'nikta',
  'nirta',
  'nižta',
  'nokta',
  'norta',
  'nykta',
  'nūsta',
  'opėta',
  'orėta',
  'perta',
  'pešta',
  'pigta',
  'pilta',
  'pinta',
  'plota',
  'plyta',
  'pulta',
  'purta',
  'pusta',
  'pykta',
  'pypta',
  'pūsta',
  'rakta',
  'rasta',
  'rauta',
  'remta',
  'rieta',
  'rikta',
  'rimta',
  'rista',
  'rišta',
  'rukta',
  'rupta',
  'rusta',
  'ryžta',
  'rėkta',
  'rėpta',
  'rėžta',
  'ręsta',
  'ręžta',
  'rūgta',
  'rūkta',
  'salta',
  'segta',
  'sekta',
  'semta',
  'senta',
  'sieta',
  'siūta',
  'sopta',
  'spėta',
  'srūta',
  'stota',
  'sukta',
  'supta',
  'susta',
  'sėsta',
  'sūrta',
  'tapta',
  'tarta',
  'tekta',
  'temta',
  'tepta',
  'težta',
  'tikta',
  'tilta',
  'tinta',
  'tirta',
  'tižta',
  'tolta',
  'treta',
  'tukta',
  'tusta',
  'tvota',
  'tykta',
  'tęsta',
  'tįsta',
  'tūpta',
  'tūžta',
  'uosta',
  'urbta',
  'urpta',
  'uršta',
  'velta',
  'vemta',
  'vepta',
  'verta',
  'vesta',
  'vežta',
  'vilta',
  'vipta',
  'virta',
  'vista',
  'vogta',
  'vokta',
  'vožta',
  'vykta',
  'vypta',
  'vysta',
  'vyžta',
  'vėpta',
  'vėsta',
  'ygzta',
  'zuita',
  'zykta',
  'zyzta',
  'zūzta',
  'ąsota',
  'čižta',
  'čypta',
  'čyžta',
  'ėjėta',
  'šalta',
  'šauta',
  'šašta',
  'šepta',
  'šerta',
  'šikta',
  'šilta',
  'šinta',
  'šipta',
  'šlyta',
  'šokta',
  'šusta',
  'švyta',
  'šėlta',
  'ūdyta',
  'ūgėta',
  'ūkyta',
  'ūmėta',
  'žagta',
  'žalta',
  'želta',
  'žerta',
  'žilta',
  'žiota',
  'žirta',
  'žysta',
  'žėbta',
  'žįsta',
  'aidėk',
  'aižyk',
  'aižėk',
  'akink',
  'aklėk',
  'akuok',
  'alink',
  'aliok',
  'alkėk',
  'almėk',
  'alpėk',
  'aluok',
  'amsėk',
  'anksk',
  'anuok',
  'ardyk',
  'arenk',
  'arkyk',
  'artėk',
  'aukok',
  'aušėk',
  'badyk',
  'badėk',
  'baigk',
  'bakok',
  'bakyk',
  'baubk',
  'bausk',
  'belsk',
  'bijok',
  'bimbk',
  'birbk',
  'bizyk',
  'bjurk',
  'bliūk',
  'blogk',
  'blukk',
  'blėsk',
  'blęsk',
  'blįsk',
  'bobėk',
  'bodėk',
  'brauk',
  'brisk',
  'brukk',
  'brėžk',
  'bręsk',
  'brįsk',
  'brūzk',
  'bubyk',
  'bubėk',
  'budėk',
  'budėk',
  'bujok',
  'burbk',
  'burkk',
  'buvok',
  'buzok',
  'bygzk',
  'bylok',
  'byrėk',
  'bėdok',
  'bėgėk',
  'būvėk',
  'cirpk',
  'dabok',
  'dagok',
  'dagėk',
  'dalyk',
  'dargk',
  'daryk',
  'daubk',
  'daužk',
  'dažyk',
  'delbk',
  'delsk',
  'dengk',
  'dergk',
  'derėk',
  'didėk',
  'diegk',
  'dingk',
  'dirbk',
  'dirgk',
  'diržk',
  'domėk',
  'dorok',
  'dorėk',
  'dribk',
  'drikk',
  'drožk',
  'drykk',
  'drėbk',
  'drėgk',
  'drėkk',
  'drįsk',
  'drūsk',
  'dujėk',
  'dulkk',
  'dulsk',
  'duobk',
  'dvakk',
  'dvisk',
  'dvokk',
  'dvėsk',
  'dygėk',
  'dykėk',
  'dyrok',
  'dyrėk',
  'dėkok',
  'dėmėk',
  'dėmėk',
  'dėvėk',
  'dūgzk',
  'dūlyk',
  'dūlėk',
  'dūmok',
  'dūmyk',
  'dūmyk',
  'dūsėk',
  'džiūk',
  'eižėk',
  'erdėk',
  'erzėk',
  'gagėk',
  'gaišk',
  'galėk',
  'ganyk',
  'ganėk',
  'gargk',
  'garėk',
  'gaubk',
  'gausk',
  'gaužk',
  'gavėk',
  'gebėk',
  'gedėk',
  'geibk',
  'geisk',
  'gelsk',
  'genėk',
  'gerbk',
  'gerėk',
  'giežk',
  'gilėk',
  'girsk',
  'gižėk',
  'glebk',
  'glerk',
  'gležk',
  'gliek',
  'glisk',
  'globk',
  'glumk',
  'glusk',
  'glėbk',
  'gnybk',
  'gobėk',
  'godok',
  'godėk',
  'gožėk',
  'griek',
  'grisk',
  'griūk',
  'grobk',
  'grobk',
  'grubk',
  'grumk',
  'grusk',
  'gruzk',
  'grėbk',
  'grėsk',
  'gręsk',
  'gręžk',
  'grįsk',
  'grįžk',
  'grūsk',
  'gubok',
  'gugėk',
  'gulėk',
  'gumbk',
  'guosk',
  'gurbk',
  'gurgk',
  'gurėk',
  'gužėk',
  'gverk',
  'gydyk',
  'gyvėk',
  'gėdyk',
  'gėdėk',
  'gėrėk',
  'gūmok',
  'gūžok',
  'gūžėk',
  'ilgėk',
  'ilsėk',
  'ingzk',
  'inkšk',
  'ižink',
  'jaugk',
  'jaukk',
  'jausk',
  'jodyk',
  'jovyk',
  'judėk',
  'jungk',
  'junkk',
  'juokk',
  'juosk',
  'jutėk',
  'kabok',
  'kabėk',
  'kaipk',
  'kaisk',
  'kaišk',
  'kalėk',
  'kapok',
  'karkk',
  'karok',
  'karsk',
  'karėk',
  'karšk',
  'kasok',
  'kasyk',
  'katėk',
  'kaukk',
  'kaupk',
  'kaušk',
  'kedėk',
  'keikk',
  'keipk',
  'keisk',
  'kekėk',
  'kemšk',
  'kenkk',
  'kerbk',
  'kergk',
  'kerok',
  'kerpk',
  'kerėk',
  'ketėk',
  'kežėk',
  'kilok',
  'kimšk',
  'kiožk',
  'kirkk',
  'kirpk',
  'kirsk',
  'kiršk',
  'kitėk',
  'kiurk',
  'kiužk',
  'klebk',
  'klekk',
  'klerk',
  'klesk',
  'klibk',
  'klirk',
  'klišk',
  'kliūk',
  'klupk',
  'klusk',
  'klykk',
  'klypk',
  'klysk',
  'klėsk',
  'knabk',
  'knebk',
  'knežk',
  'knibk',
  'kniek',
  'knisk',
  'knykk',
  'koryk',
  'korėk',
  'kosėk',
  'kovok',
  'kragk',
  'krauk',
  'krekk',
  'krešk',
  'krikk',
  'krisk',
  'krupk',
  'krušk',
  'krykk',
  'krypk',
  'krėsk',
  'krėsk',
  'krūpk',
  'kuisk',
  'kukėk',
  'kuopk',
  'kurkk',
  'kurpk',
  'kursk',
  'kužėk',
  'kvipk',
  'kvosk',
  'kvykk',
  'kvėpk',
  'kvėšk',
  'kybok',
  'kylėk',
  'kyrėk',
  'kyšok',
  'kyšėk',
  'kėdok',
  'kėsok',
  'kėtok',
  'kūlėk',
  'kūnyk',
  'kūnėk',
  'kūpėk',
  'lalėk',
  'lapok',
  'lasyk',
  'laukk',
  'laupk',
  'laužk',
  'lavėk',
  'lašėk',
  'ledyk',
  'ledėk',
  'leipk',
  'leisk',
  'lenkk',
  'liauk',
  'liepk',
  'liesk',
  'liežk',
  'ligok',
  'linkk',
  'lipyk',
  'liūsk',
  'lodyk',
  'lopyk',
  'lubok',
  'luošk',
  'lydyk',
  'lydėk',
  'lygėk',
  'lykyk',
  'lynok',
  'lypėk',
  'lytėk',
  'lėtėk',
  'lūkėk',
  'lūžėk',
  'magėk',
  'makok',
  'malšk',
  'manyk',
  'margk',
  'matyk',
  'maukk',
  'mausk',
  'mažėk',
  'medėk',
  'megzk',
  'meišk',
  'melsk',
  'melžk',
  'menkk',
  'merkk',
  'metėk',
  'miešk',
  'migzk',
  'milžk',
  'minėk',
  'mirkk',
  'miršk',
  'mišyk',
  'mokyk',
  'mokėk',
  'molėk',
  'movėk',
  'murkk',
  'mylėk',
  'mėtyk',
  'mūryk',
  'mūsok',
  'mūsyk',
  'mūvėk',
  'neigk',
  'neršk',
  'niauk',
  'niežk',
  'nigzk',
  'nirsk',
  'niršk',
  'niukk',
  'niurk',
  'niūkk',
  'norėk',
  'novyk',
  'nyrok',
  'nyrėk',
  'ošink',
  'paikk',
  'palšk',
  'pampk',
  'pamėk',
  'parpk',
  'pašyk',
  'peikk',
  'pelyk',
  'pelėk',
  'penėk',
  'perėk',
  'peršk',
  'piepk',
  'piešk',
  'pigėk',
  'pijok',
  'pilkk',
  'pinok',
  'pirkk',
  'pirpk',
  'piršk',
  'pjauk',
  'plakk',
  'plauk',
  'plerk',
  'plikk',
  'plisk',
  'plukk',
  'plušk',
  'plyšk',
  'plėkk',
  'plėsk',
  'plėšk',
  'plūkk',
  'plūsk',
  'ponėk',
  'prask',
  'prusk',
  'puikk',
  'pukšk',
  'puošk',
  'purpk',
  'pursk',
  'purėk',
  'putok',
  'putok',
  'pydyk',
  'pynėk',
  'pėryk',
  'pūdyk',
  'pūgok',
  'pūkyk',
  'pūkšk',
  'ragėk',
  'raibk',
  'raišk',
  'rasok',
  'raugk',
  'raukk',
  'rausk',
  'ravėk',
  'rašyk',
  'regzk',
  'regėk',
  'reikk',
  'remžk',
  'rengk',
  'retėk',
  'ribok',
  'ribėk',
  'ridėk',
  'riekk',
  'riesk',
  'rigzk',
  'rinkk',
  'ritėk',
  'riūkk',
  'rodyk',
  'romyk',
  'ropok',
  'ropšk',
  'ropšk',
  'rudėk',
  'rujok',
  'rumžk',
  'rungk',
  'ruopk',
  'ruošk',
  'rusėk',
  'rydyk',
  'rykšk',
  'rymok',
  'rytėk',
  'ryšėk',
  'rąžyk',
  'rėkšk',
  'rėžyk',
  'rūdyk',
  'rūgėk',
  'rūkyk',
  'rūpėk',
  'rūsok',
  'sakyk',
  'salsk',
  'sausk',
  'segėk',
  'senėk',
  'siekk',
  'sijok',
  'silpk',
  'sirgk',
  'sirpk',
  'siusk',
  'siųsk',
  'skark',
  'skask',
  'skelk',
  'skilk',
  'skink',
  'skirk',
  'skisk',
  'skobk',
  'skubk',
  'skurk',
  'skusk',
  'skysk',
  'skėsk',
  'skęsk',
  'skųsk',
  'slopk',
  'slysk',
  'slėgk',
  'slėpk',
  'slūgk',
  'smegk',
  'smigk',
  'smilk',
  'smogk',
  'smukk',
  'smėlk',
  'snigk',
  'snūsk',
  'sopėk',
  'spirk',
  'spisk',
  'spurk',
  'spęsk',
  'spįsk',
  'srėbk',
  'stebk',
  'stigk',
  'stipk',
  'stirk',
  'stokk',
  'stumk',
  'stybk',
  'stygk',
  'stypk',
  'stėrk',
  'sulpk',
  'sulūk',
  'sunkk',
  'suokk',
  'suopk',
  'suosk',
  'suužk',
  'svaik',
  'sverk',
  'svilk',
  'svirk',
  'svisk',
  'syjėk',
  'sėdėk',
  'sėdėk',
  'sūdyk',
  'sūnyk',
  'sūrėk',
  'tamsk',
  'tapok',
  'tapyk',
  'tarpk',
  'tausk',
  'tašyk',
  'teigk',
  'teikk',
  'teisk',
  'tekėk',
  'telkk',
  'telžk',
  'tempk',
  'tenėk',
  'terpk',
  'teršk',
  'tesėk',
  'težėk',
  'tiekk',
  'tiesk',
  'tikėk',
  'tikšk',
  'tilpk',
  'tilžk',
  'tingk',
  'tirpk',
  'tolėk',
  'tremk',
  'trešk',
  'trikk',
  'trink',
  'trukk',
  'trupk',
  'trypk',
  'trėsk',
  'tręšk',
  'trūkk',
  'tulžk',
  'tuokk',
  'tupėk',
  'tupėk',
  'turkk',
  'tursk',
  'turėk',
  'turšk',
  'tverk',
  'tvilk',
  'tvink',
  'tykok',
  'tylėk',
  'tyrėk',
  'tysok',
  'tąsyk',
  'tėkšk',
  'tįsok',
  'tįsėk',
  'tūlok',
  'tūnok',
  'ugdyk',
  'ulbėk',
  'uldėk',
  'unkšk',
  'uolėk',
  'urdėk',
  'urenk',
  'urgzk',
  'urkšk',
  'usnėk',
  'vadėk',
  'vagok',
  'vajok',
  'valyk',
  'vanok',
  'vapėk',
  'vargk',
  'varyk',
  'vazok',
  'vedėk',
  'veikk',
  'veisk',
  'vejok',
  'velyk',
  'velėk',
  'vengk',
  'vepėk',
  'vergk',
  'verkk',
  'verpk',
  'versk',
  'veržk',
  'vešėk',
  'viepk',
  'vijok',
  'vilgk',
  'vilkk',
  'virbk',
  'virsk',
  'vožėk',
  'vyrėk',
  'vytėk',
  'vėsėk',
  'vėtyk',
  'yvauk',
  'zaugk',
  'zaukk',
  'zembk',
  'zengk',
  'zimbk',
  'zirkk',
  'zirzk',
  'zmekk',
  'zujok',
  'zurgk',
  'zurzk',
  'čežėk',
  'čirpk',
  'čiupk',
  'čiurk',
  'ėdink',
  'ėmiok',
  'įdėbk',
  'įgalk',
  'įkirk',
  'šakok',
  'šaukk',
  'šažėk',
  'šelpk',
  'šiepk',
  'širmk',
  'širsk',
  'širšk',
  'šiupk',
  'šiurk',
  'šlapk',
  'šliek',
  'šlubk',
  'šluok',
  'šlėkk',
  'švisk',
  'švęsk',
  'šykšk',
  'ščiūk',
  'ūbauk',
  'ūkauk',
  'ūksėk',
  'ūkuok',
  'ūmauk',
  'ūmink',
  'ūžauk',
  'ūžink',
  'ūžuok',
  'žabok',
  'žadėk',
  'žaisk',
  'žaisk',
  'žalok',
  'žalėk',
  'žavėk',
  'žeisk',
  'želėk',
  'žembk',
  'žemėk',
  'žengk',
  'žergk',
  'žibėk',
  'žiebk',
  'žiesk',
  'žilpk',
  'žilėk',
  'žinok',
  'žlebk',
  'žlugk',
  'žlėgk',
  'žnekk',
  'žnybk',
  'žolėk',
  'žudyk',
  'žydėk',
  'žymėk',
  'žėrėk',
]
